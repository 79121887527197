/* .emprendimientomarketing__programar {
  position: relative;
  margin-top: -5rem;
  margin-bottom: 5rem;
}
.emprendimientomarketing__agendar {
  margin: 0 auto;
  width: 80%;
  color: var(--texto);
  background-color: #fff;
  border: 1px solid var(--texto-claro);
  padding: 4rem 3rem;
}
.emprendimientomarketing__agendar p {
  font-size: 1.6rem;
  line-height: 2;
}
.emprendimientomarketing__btn {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
} */

/* *************************************** */
/* MARKETING AGENDAMIENTO */
/* *************************************** */

.marketingetapa__container {
  margin: 4rem auto;
  width: 70%;
  color: var(--texto);
  background-color: #fff;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  /* border: 1px solid var(--texto-claro); */
  padding: 5rem 7rem;
}

.marketingetapa__container h1 {
  text-align: center;
}

.marketing__container-title {
  display: flex;
  align-items: center !important;
  justify-content: center;
  margin-bottom: 5rem;
}

.marketing__container-title img {
  display: block;
  margin-right: 1.6rem;
}

.marketingetapa__container p {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.marketing__form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 2rem;
}

.marketing__form-group {
  display: flex;
  flex-direction: column;
  /* flex-basis: 50rem; */
}

.marketing__unificado {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.marketing__unificado-item {
  display: flex;
}

.marketing__unificado-item span {
  font-size: 1.6rem;
  margin-left: .3rem;
  margin-right: 1.7rem;
}

.marketing__unificado input {
  font-size: 2rem;
  transform: scale(1.3);
}

.marketing__form-group label {
  font-size: 16px;
  margin-bottom: 10px;
  font-family: 'Scotia-Bold', 'Scotia', 'Open Sans', sans-serif;
}

.marketing__form-group input {
  padding: 1em 1em 0.8em;
  border-radius: 8px;
  /* background-color: #F9FAFC; */
  /* border: 1px solid var(--plomo); */
  border: 1px solid #979797;
}

.obligatorio.alert{
  border-color: #ff0000 ;
}

.obligatorio{
  font-size: 15px!important;
}

.custom-checkbox input {
  padding: 1em 1em 0.8em;
  margin-right: 1rem;
}

.custom_attachment {
  font-size: 15px;
  color: #858B8C;
  padding: 1em 1em 0.8em;
  border: 1px solid #979797;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 1em;
  cursor: pointer;
}

/* .custom_attachment:hover {
  background-color: var(--rojo);
  color: #fff;

}

.custom_attachment.active {
  background-color: var(--rojo);
  color: #fff;
} */

/* .custom_attachment.active:before,
.custom_attachment:hover:before {
  filter: brightness(100);
} */


.custom_attachment:before {
  content: '';
  height: 18px;
  width: 18px;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 13L1 14C1 15.6569 2.34315 17 4 17L14 17C15.6569 17 17 15.6569 17 14L17 13M13 5L9 1M9 1L5 5M9 1L9 13' stroke='%23EC111A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

}

.marketing__unificado>input[type="checkbox"]:checked {
  color: red !important;
  background-color: red !important;
}

.marketing__form-group.ancho {
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0;
}

.marketing__form-group.ancho textarea {
  border-radius: 1rem;
  border: 1px solid var(--plomo);
  padding: 1rem;
  font-size: 15px;
}

.marketing__btn {
  /* width: 100%; for marketing form */
  margin: 2rem 2rem !important;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 3;
}

.marketing__btn button {
  width: 23rem;
  padding: 1.2rem 2rem;
  border-radius: 1rem;
  border: 1px solid #fff;
  font-weight: 700;
  cursor: pointer;
  /* background-color: var(--rojo); */
}

.box_hv_servicios {
  width: 80%;
  margin: 8rem auto;
}

.box_hv_servicios>div {
  width: 90%;
  text-align: left;
  margin: 50px auto 500px;
  font-size: 16px;
  color: #333333;
}

.box_hv_servicios h2 {
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
}

@media screen and (min-width: 641px) {
  .marketing__form-group.ancho {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .marketing__form {
    grid-template-columns: repeat(auto-fit, minmax(39rem, 1fr));
  }

  .marketing__form-group.ancho textarea {
    height: 178px;
  }
}

@media (min-width: 641px) and (max-width: 1359px) {
  .marketing__form {
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }
}

@media screen and (max-width: 640px) {
  .marketingetapa__container {
    /* margin: 0 auto; */
    width: 90%;
    box-shadow: none !important;
    /* color: var(--texto); */
    /* background-color: #fff; */
    /* border: 1px solid var(--texto-claro); */
    border: none;
    padding: 1rem;
  }

  .marketing__container-title {
    justify-content: start !important;

  }

  .marketing__form-group input {
    padding: 1rem;
    /* width: 100%; */
  }

  .marketing__form-group.ancho {
    grid-column-start: 1;
    grid-column-end: 2;
    padding: 0;
  }

  .marketing__form-group.ancho textarea {
    padding: 1rem;
  }

  .marketing__btn {
    width: 70% !important;
    margin: 0rem auto;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .marketing__unificado {
    flex-direction: column !important;
    justify-content: start !important;
    align-items: start !important;
  }

  .marketing__unificado span {
    margin-left: 1rem;
  }

  .marketing__btn button {
    width: 15rem;
  }

  .btnwith23 {
    width: 23rem !important;
  }

}
input[type="checkbox"]:checked  {
  color: var(--rojo);
  accent-color: var(--rojo);
}
