
.out__compania-title{
    /* font-size: 3.6rem; */
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 2rem;
    font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
}
.out__compania-cards{
    background-color: var(--bg_grey);
    padding: 4rem 15rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 2rem; 

}
.out__compania-card{
    padding: 2rem;
    box-shadow: 0.3rem 0.3rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    background-color: #fff;
}
.compania__header{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.compania__header img{
    width: 6.4rem;
    height: 6.4rem;
    object-fit: cover;
}
.compania__header-title{
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--texto-dark);
}
.compania__header-subtitle{
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--texto);
}
.compania__body{
    margin-top: 1rem;
    color: var(--texto-semidark);
    font-size: 1.6rem;
    font-weight: 400;
}

.out__process-divider{
    margin-top: 4rem;
    margin-bottom: 4rem;
    align-self: center;
    width: 90px;
    height: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 768px){

    .out__process-divider{
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 90%;
    }

    .out__compania-title{
        font-size: 2.4rem;
        margin-top: 3rem;
    }
    .out__compania-cards{
        padding: 4rem 3rem;
        display: flex;
        overflow-x: scroll;
    }
    .out__compania-card{
        min-width: 25rem;
    }
}