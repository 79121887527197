.container-perfil{
    margin-top: -16rem;
}
.conteiner_perfil-content{
    width: 70%;
    margin: auto;
}
.conteiner_perfil-titulo{
    text-align: center;
    padding-top: 10rem !important;
}
.conteiner_perfil-titulo h2{
    font-size: calc(2rem + 1vw);
    margin-bottom: 1rem;
}
.conteiner_perfil-titulo p{
    font-size: 15px;
    margin-bottom: 3rem;
}
.container_perfil-form{
    width: 90%;
    margin: auto;
    /* box-shadow: 0 0 10px 3px rgba(26, 26, 26, 0.084); */
    padding: 3rem 2rem;
}
.container_perfil-form h2{
    margin-bottom: 4rem;
    font-size: calc(2rem + .7vw);
}
.container_perfil-form-all{
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2,1fr);
}
.container_perfil-form-item{
    margin-bottom: 2rem !important;
}
.container_perfil-form-item p{
    margin-bottom: 1rem;
}
.container_perfil-form-item input{
    width: 85% !important;
    padding: 13px !important;
    border: solid 1px var(--borde);
}
.container_perfil-button{
    padding: 15px 30px;
    border-radius: 2rem;
    border: none;
    color: white;
    background-color: var(--rojo);
    margin: 3rem auto;
    display: block;
    font-weight: bold;

}

/* password */
.conteiner_perfil-password{
    width: 90%;
    margin: auto;
}
.conteiner_perfil-password h2{
    font-size: calc(2rem + .6vw);
    margin: 3rem auto;
}
.conteiner_perfil-password-item p{
    margin-bottom: 1rem;
}
.conteiner_perfil-password-item input{
    width: 85% !important;
    padding: 13px !important;
    border: solid 1px var(--borde);
    margin-bottom: 2rem;
    border-radius: 15px;
    margin-left: 1rem !important;  
    outline: none;
}

.conteiner_perfil-password-buttons{
    width: 80%;
    margin-left: 3rem;
    display: flex;
    align-items: center;
    margin-top:2rem;
    margin-bottom: 10rem;
    justify-content: space-between;
}
.conteiner_perfil-password-buttons button{
    padding: 15px 30px;
    font-weight: bold;
    cursor: pointer;
}
.perfil_button-show{
    border-radius: 10px;
    background-color: transparent;
    border: solid 1px var(--rojo);
    color: var(--rojo);
}
.perfil_button-next{
    background: var(--rojo);
    color: white;
    border: none;
    border-radius: 2rem;
    
}
@media (max-width:800px){
    .conteiner_perfil-titulo h2{
        margin-top: -3rem;
    }
    .conteiner_perfil-titulo p{
        margin-bottom: 3rem;
    }
    .conteiner_perfil-content{
        width: 100%;
    }
    .container_perfil-form{
        width: 100%;
    }
    .container_perfil-form-all{
        grid-template-columns: repeat(1,1fr);
    }
    .container_perfil-form-item input{
        width: 95% !important;
    }

    /* pássword */
    .conteiner_perfil-password-buttons{
        flex-direction: column-reverse;
    }
    .conteiner_perfil-password-item input{
        width: 100% !important;
        margin-left: 0 !important;
    }
    .perfil_button-show{
        margin-bottom: 2rem;
    }
}