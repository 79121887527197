/* ***************************************** */
/* HOME OUT PROTECCION */
/* ***************************************** */
.pasos__group {
  display: flex;
  gap: 2em;
  justify-content: space-between;
  padding: 1rem 3rem;
  transition: all 250ms ease-in-out;
}

.otrosbeneficios__group-detalle {
  display: flex;
  flex-direction: row;
  gap: 2em;
  justify-content: center;
}

.proteccion__presentacion {
  display: flex;
  justify-content: center;
}

.proteccion__presentacion-img,
.proteccion__presentacion-textos {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.proteccion__presentacion-img {
  flex: 7;
}

.proteccion__presentacion-textos.verde {
  background-color: var(--verde);
}

.proteccion__presentacion-img.verde {
  background-color: var(--verde-claro);
}

.proteccion__presentacion-img div {
  display: flex;
  justify-content: center;
  height: 53rem;
}

.proteccion__presentacion-img img {
  height: 100%;
  width: 100%;
}

.proteccion__presentacion-textos {
  background-color: var(--fondo-principal-text);
  flex: 6;
  color: #fff;
}

.proteccion__presentacion-textos div h2 {
  font-size: 4.2rem;
  letter-spacing: -2px;
  font-weight: bold;
}

.proteccion__presentacion-textos div p:nth-child(1) {
  margin-top: 2rem;
}

.proteccion__presentacion-textos div p {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.proteccion__btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.proteccion__out-btns {
  display: flex;
  font-size: 5rem;
  justify-content: center;
  margin-top: 4rem;
}

.courseraout-titulos {
  margin-top: 2rem;
}

.courseratitulos {
  font-size: 2.8rem;
  text-align: center;
  font-weight: 700;
  padding: 1.6rem;
  margin-top: 7rem;
}

.courseraout-imagenes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  width: 90%;
  margin: auto;
  overflow: hidden;
  padding: 1.6rem;
}

.outcoursera__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tabsWrapper-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 20px;
  font-weight: bold;
}

.tabsIdle {
  padding: 5px 10px;
  cursor: pointer;
  transition: all 500ms;
  border-bottom: 4px solid #ffffff;
  position: relative;
}

.UItabsCard {
  transition: all 500ms;
  cursor: pointer;
  box-shadow: 0px 4px 16px rgba(69, 91, 99, 0.14);
}

.UItabsCard:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(69, 91, 99, 0.54);

}

.tabsIdle:hover:after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
  animation: underdashIn 500ms;
}

@keyframes underdashIn {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.fadein {
  opacity: 1;
  transform: translateX(0);
}

.fadeOut {
  opacity: 0;
  transform: translateX(-10px);
}

.tabsActive {
  color: #009DD6;
  padding: 5px 10px;
  border-bottom: 4px solid #009DD6;
}
.tabs__group{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4em;
  gap: 2rem;
}
@media screen and (max-width: 768px) {

  .tabs__wrapper{
    display: block;
    width: 100vw;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
  }

  .pasos__group {
    flex-direction: column;
  }

  .otrosbeneficios__group-detalle {
    flex-direction: column;
  }

  .courseraout-imagen {
    width: 10rem;
    height: 10rem;
    object-fit: contain;
  }

  .courseratitulos {
    font-size: 2rem;
    padding: 0 1rem;
  }

  .tabsWrapper-main {
    gap: 1rem;
  }
}


@media screen and (max-width: 764px) {

  .tabs__group {
    overflow-x: scroll;
    width: 100vw;
    padding: 2em 4em;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }

  .tabsWrapper-main {
    overflow-x: scroll;
    width: 100vw;
    padding: 2em 4em;
    display: flex;
    justify-content: flex-start;
  }

  .tabsWrapper-main .tabs {
    font-size: 14px;
    flex-grow: 1;
    width: 250px;
    flex-shrink: 0;
  }

  .tabs {
    width: 100%;
    flex-shrink: 0;
  }

  /* custom scrollbar - width */
  .tabs__group::-webkit-scrollbar,
  .tabsWrapper-main::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  .tabs__group::-webkit-scrollbar-track,
  .tabsWrapper-main::-webkit-scrollbar-track {
    background-color: #ECEEF0;
    border-radius: 10px;
  }

  /* Handle */
  .tabs__group::-webkit-scrollbar-thumb,
  .tabsWrapper-main::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border-radius: 10px;
  }

  /* Handle on hover */
  .pasos__group::-webkit-scrollbar-thumb:hover,
  .tabsWrapper-main::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
}