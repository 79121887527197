.proteccion__titulo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15rem;
  color: #fff;
  display: flex;
  justify-content: center;
}

.proteccion__titulo_perfil {
  height: 15rem;
  color: red;
  display: flex;
  justify-content: center;
}
.proteccion__titulo_perfil.naranja {
  background-color: var(--rojo);
}
.proteccion__titulo.rojo {
  background-color: var(--rojo);
}
.proteccion__titulo.verde {
  background-color: var(--verde);
}
.proteccion__titulo.naranja {
  background-color: var(--naranja);
}
.proteccion__titulo.morado {
  background-color: var(--morado);
}
.proteccion__titulo.azul {
  background-color: var(--azul);
}
.proteccion__titulo.celeste {
  background-color: var(--celeste);
}
.proteccion__titulo.naranja-claro {
  background-color: var(--naranja-claro);
}
.proteccion__titulo.morado-claro {
  background-color: var(--morado-claro);
}
.proteccion__titulo.verde-gris {
  background-color: var(--verde-gris);
}
.proteccion__titulo.naranjo-rosado {
  background-color: var(--naranjo-rosado);
}
.proteccion__titulo.naranja2 {
  background-color: var(--naranja2);
}
.proteccion__titulo > div {
  width: 70%;
}
.proteccion__titulo h2 {
  letter-spacing: -2px;
  font-size: 4.2rem;
  padding: 4rem 0 2rem 0;
}
.proteccion__titulo p {
  font-size: 1.8rem;
}

@media screen and (max-width: 640px) {
  .proteccion__titulo h2 {
    font-size: 2.2rem;
  }
  .proteccion__titulo p {
    font-size: 1.2rem;
  }

  .proteccion__titulo {
    height: 16rem;
  }
}
