.out__contacto {
  padding: 4rem 15rem;
  background: #EEEEEE;
}

.out__contacto__wrapper{
  position: absolute;
  width: 100%;
}

.contacto__contactanos {
  display: flex;
  justify-content: center;
  border-top: 1px solid #8D8D8D;
  border-bottom: 1px solid #8D8D8D;
  padding: 3rem 0 2rem;
  gap: 3rem;
}
.contacto__contactanos-email,
.contacto__contactanos-llamada {
  flex: 1;
}
.contacto__contactanos-email {
  /* text-align: center; */
  font-size: 1.6rem;
  font-weight: 700;
}
.contacto__contactanos-email p span {
  color: var(--rojo);
}
.contacto__contactanos-llamada,
.contactanos__llamada-group,
.contactanos__redes,
.contactanos__redes div,
.contactanos__llamada {
  display: flex;
  gap: 1rem;
}
.contacto__contactanos-llamada {
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--texto-contacto);
}
.contactanos__llamada-group {
  flex-direction: column;
  justify-content: center;
}
.contactanos__llamada {
  justify-content: space-between;
  min-width: 22rem;
  font-size: 16px;
  color: black;
}
.contactanos__redes {
  align-items: center;
  margin-left: 2rem;
}
.contactanos__redes p {
  min-width: 10rem;
}
.contactanos__redes img {
  height: 4rem;
  width: 4rem;
}

.contacto__conocenos {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contacto__conocenos.resumen {
  margin-top: 3rem;
  /* display: flex; */
}
.contacto__conocenos-texto,
.contacto__conocenos-mapa {
  flex: 1;
}


.contacto__conocenos-texto {
  display: flex;
  justify-content: flex-start;
}
.contacto__conocenos-texto.resumen{
  padding-right: 3rem;
}
.conocenos__texto-conocenos,
.conocenos__texto-legales {
  font-size: 1.4rem;
  font-weight: 700;
}
.conocernos__texto-item {
  margin-bottom: 2rem;
  cursor: pointer;
}
.conocernos__texto-item.claro {
  color: var(--texto-input);
  cursor: default;
}
.conocenos__texto-legales {
  display: flex;
}
.contacto__conocenos-mapa {
  height: 40rem;
}
.contacto__conocenos-mapa img {
  height: 100%;
}
.divisor{
  border: solid 0.5px black;
  height: 2.1rem;
  margin: 0 2rem 1.3rem 2rem;
}

.contacto__links {
  padding-top: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--texto-contacto);
  border-top: 1px solid var(--borde-claro);
  margin-top: 1rem;
}
.contacto__links p {
  cursor: pointer;
}

.contacto__resumen{
  margin-top: auto;
  pointer-events: all;
  padding: 0rem 15rem;
  background-color: #EEEEEE;
  padding-top: 2rem;
}
.contacto__conocenos-link{
  flex: 1;
  display: flex;
  align-items: center;
}
.contacto__link-resumen{
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: center;
  font-size: 1.4rem;
  color: var(--texto-contacto);
}
.contacto__link-resumen p{
  margin-right: 15rem;
}

@media screen and (max-width: 768px) {

  .contacto__conocenos-texto{
    padding: 0;
  }

  .conocernos__texto-item {
    font-size: 14px;
  }
  .divisor{
    display: none;
  }
  .conocernos__texto-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0 0rem; 
  }
}

@media screen and (max-width: 640px) {
  .contacto__resumen{
    padding: 2rem 3rem;
    margin-top: 5rem;
  }
  .out__contacto {
    padding: 4rem 3rem;
  }
  .contacto__contactanos,
  .contacto__contactanos-llamada,
  .contactanos__redes {
    flex-direction: column;
    gap: 2rem;
  }
  .contacto__contactanos {
    border: none;
  }
  .contacto__contactanos-email {
    justify-content: center;
    font-size: 1.8rem;
  }
  .contactanos__llamada {
    padding: 0 0rem;
    font-size: 15px;
  }
  .contacto__contactanos-email p span {
    margin-left: 0rem;
    display: block;
  }
  .contactanos__redes div {
    width: 100%;
    justify-content: space-between;
  }

  .contacto__conocenos-texto {
    flex-direction: column;
  }

  .contacto__conocenos-texto.resumen{
    padding-right: 0;
  }

  .conocenos__texto-legales {
    max-width: 100%;
    flex-direction: column;
  }
  .conocernos__texto-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0 0rem; 
  }
  .divisor{
    display: none;
  }

  .conocenos__texto-group {
    border-top: 1px solid var(--borde-claro);
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
  }
  .conocenos__texto-group i{
    color: var(--rojo);
  }

  .conocernos__texto-item.claro {
    color: #868B8B;
  }
  .contacto__conocenos {
    flex-direction: column;
  }

  .contacto__conocenos-mapa {
    height: 20rem;
  }
  .contacto__conocenos-mapa img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  .contacto__links {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 4rem;
    margin: 0;
  }
  .contacto__link-resumen{
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .contacto__link-resumen p{
    margin: 0;
  }
  .borde__separador {
    border-top: 1px solid #8D8D8D;
  }
}

@media screen and (min-width: 640px){
  .conocenos__texto-group{
    display: flex !important;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .conocenos__texto-legales{
    width: 100%;
  }
}
