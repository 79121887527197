.homeout__logos {
  display: flex;
  align-items: center;
  gap: 4rem;
  /* background: var(--bg_claro); */
  background: #EEEEEE ;
}
.homeout__logos.centro {
  justify-content: center;
}
.homeout__logos.izquierda {
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: flex-start;
}

.homeout__logos img {
  height: 7.3rem;
}
.homeout__logos.izquierda img {
  height: 3rem;
}

@media screen and (max-width: 768px) {
  .homeout__logos img {
    height: 4rem;
  }
  .homeout__logos.izquierda {
    justify-content: center;
    margin-left: 0px;
    gap: 2rem;
    margin-bottom: -3rem;
    margin-top: 7rem;
  }
}
