/* ************************************************* */
/* HOME IN MODULOS */
/* ************************************************* */
.homein{
  display: flex;
  flex-grow: 1;
  position: relative;
  gap: 2rem;
  margin-bottom: 5%;
}
.homein__resumen {
  width: 30rem;
  /* background-color: #fff; */
  margin-top: 5rem;
  z-index: 2;
  /* padding: 1rem; */
  margin-left: 40px;
  /* border-radius: 1rem; */
  /* box-shadow: 3px 5px 10px rgba(68, 97, 245, 0.17); */
}
.homein__detalle{
  flex: 1;
  background-color: transparent;
  margin-top: 8rem;
  z-index: 2;
  padding: 1rem;
}

.homein__detalle_submodulo{
  font-size: 18px;
  font-weight: 400;
  color: var(--texto);
  width: 80%;
  text-align: left;
  margin: 15rem auto;
}
.homein__detalle_submodulo p{
  font-size: 17px;
  line-height: 26px;
}


/* ************************************************* */
/* HOME IN MODULOS */
/* ************************************************* */
.proteccion__configuracion{
  height: 20rem;
  color: black;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px){

  .homein__detalle{
    margin-top: 0rem;
    padding: 3rem;
  }

  .homein__detalle_submodulo{
    font-size: 1.4rem;
    margin: 2rem auto 5rem;
    padding: 3rem;
    width: 90%;
    line-height: 25px;
    text-align: justify;
  }
  

}

@media screen and (max-width: 1130px) {

  .homein__resumen {
    width: auto;
    margin-right: 40px;
  }

  .homein{
    flex-direction: column;
  }
}














