.encuesta__salud {
}

.addpad {
  padding-left: 6rem;
  padding-right: 6rem;
}

.addpadPregunta {
  width: 80%;
  margin: auto;
}

.titulo {
  color: #60666b;
  font-size: 28px;
  font-weight: 700;
  /* font-family: 'Open Sans'; */
}

.textodes {
  color: #60666b;
  font-size: 16px;
  /* font-family: 'Open Sans'; */
  margin: 20px 0;
}

.form-group-salud.form-check {
  border: 1px solid #4f5053;
  border-radius: 6px;
  padding: 1em;
  margin-bottom: 16px;
}

.gridNota {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.gridNota > div {
  box-shadow: 0px 0px 0.2rem var(--borde);
  text-align: center;
  font-size: 16px;
  padding: 1rem;
}

.nota__resultado {
  width: 100%;
  color: #fff;
  padding: 0.5rem;
  border-radius: 2rem;
  font-size: 1.4rem;
}

.nota__resultado.azul {
  background-color: var(--azul);
}

.nota__resultado.verde {
  background-color: #44a246;
}

.nota__resultado.amarillo {
  background-color: #e8ce31;
}

.nota__resultado.rojo {
  background-color: #da291c;
}

.ejercicio__titulo {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--texto-dark);
  margin-top: 1rem;
}

.gridCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  padding-top: 3rem;
  margin-bottom: 3rem;
}

.textoponderacion {
  color: #60666b;
  font-size: 20px;
  /* font-family: 'Open Sans'; */
  margin: 20px 0;
}

.ponderacion {
  font-weight: bold;
  font-size: 26px;
}

.ponderacion.red,
.textoponderacion.red {
  color: red;
}

.ponderacion.orange,
.textoponderacion.orange {
  color: orange;
}

.ponderacion.azul,
.textoponderacion.azul {
  color: #3366ff;
}

.ponderacion.green,
.textoponderacion.green {
  color: green;
}

.textComent {
  /* font-weight: 700; */
  margin: 1rem auto 1.5rem;
  font-size: 16px;
  text-align: center;
}

.textComentTitle {
  font-weight: 700;
  margin: 1rem auto 1.5rem;
  font-size: 25px;
  text-align: center;
}

.number {
  color: #363c49;
  background: rgba(19, 132, 104, 0.16);
  border-radius: 50%;
  padding: 1rem;
  width: 35px;
  height: 35px;
  text-align: center;
  margin-right: 15px;
}

.flexPregunta {
  display: flex;
  margin-top: 5rem;
}

.textComentlabel {
  font-weight: 700;
  margin: 1rem auto 1.5rem;
  font-size: 15px !important;
  text-align: left !important;
  line-height: normal !important;
  display: inline-block;
  margin-bottom: 1rem;
  width: 100%;
}

.aling-left {
  text-align: left;
}

.aling-center {
  text-align: center;
}

.form-group-salud {
  margin-bottom: 1rem;
}

.form-control-salud {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;

  border-radius: 3px !important;
  border: solid 1px #bababa !important;
  background-color: #ffffff !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: normal !important;
  color: #9b9b9b !important;

  margin: 10px 0 0px 50px;
  /* font-family: inherit; */
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  text-align: left;
  margin-left: 35px;
}

.form-check1 {
  position: relative;
  display: block;
  padding-left: 5.25rem;
  text-align: left;
}

.form-check-label {
  margin-bottom: 0;
  font-weight: normal !important;
}

.form-check-label1 {
  margin-bottom: 0;
  font-weight: normal !important;
  font-size: 20px;
}

hr {
  width: 100%;
  margin: 40px auto;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  font-size: 12px !important;
  border-collapse: collapse;
  margin-left: 50px;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.mid {
  width: 25%;
}

.mid6 {
  width: 16.66%;
  font-size: 10px !important;
  padding: 10px;
  text-align: left !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.pad1rem {
  padding: 1rem;
}

.check__notificaciones:checked {
  background-color: #123 !important;
}

.btnSend {
  width: 25rem;
  background-color: var(--fondo-principal-text);
  padding: 1.2rem 2rem;
  border-radius: 8px;
  border: 1px solid var(--fondo-principal-text);
  color: #ffffff;
  font-weight: 700;
  font-size: 1.6rem;
  cursor: pointer;
  text-align: center;
  margin: 4rem auto;
}

.btnAtras {
  width: 25rem;
  background-color: #fff;
  padding: 1.2rem 2rem;
  border-radius: 8px;
  border: 1px solid var(--fondo-principal-text);
  color: var(--fondo-principal-text);
  font-weight: 700;
  font-size: 1.6rem;
  cursor: pointer;
  text-align: center;
  margin: 4rem auto;
}

.btnSend:disabled {
  width: 25rem;
  background-color: gray;
  padding: 1.2rem 2rem;
  border-radius: 8px;
  border: 1px solid gray;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.6rem;
  cursor: pointer;
  text-align: center;
  margin: 4rem auto;
}

.sinbold {
  font-weight: normal;
  margin-left: 50px;
}

.inline {
  display: flex;
  width: 100%;
}

.textComentdiv {
  font-weight: normal;
  margin: 1rem auto 1.5rem;
  font-size: 16px !important;
  text-align: left !important;
  line-height: normal !important;
  display: inline-block;
  margin-bottom: 0.5rem;
  width: 100%;
}

.textComenttable {
  font-weight: normal;
  margin: 1rem 10px 1.5rem;
  font-size: 12px !important;
  text-align: left !important;
  line-height: normal !important;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.der {
  width: 20%;
}

.izq {
  width: 60%;
}

.der1 {
  width: 30%;
}

.izq1 {
  width: 40%;
}

.tdsalud {
  border-top: 0px;
  text-align: center;
}

.rdPreguntaRango {
  margin-top: 6px;
  margin-left: -2px;
  cursor: pointer;
}

.btnOpcionesSelect {
  display: flex;
  width: 100% !important;
}

.agenda__btn {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.agenda__btn button:disabled {
  cursor: no-drop;
  background-color: #D9D9D9;
}

.agenda__btn button:hover {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #fff !important;
}

.izquierda {
  justify-content: start;
  margin-left: 30px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 640px) {
  .addpad {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .ejercicio__titulo {
    font-size: 1.4rem;
  }

  .ejercicio__titulo p {
    font-size: 1.2rem;
  }

  .gridNota {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

.btnActualizaDatos {
  color: black;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  background-color: #91ddf8;
}

.formBeneficiario label {
  font-size: 1.3rem;
}

.formBeneficiario p {
  font-size: 1.1rem;
}

.formBeneficiario a {
  color: #009dd6;
  text-decoration: underline;
}

.form-control-salud-beneficiario {
  display: block;
  width: 100%;
  max-width: 500px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  border-radius: 3px !important;
  border: solid 1px #bababa !important;
  background-color: #ffffff;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: normal !important;
  color: #7f7f7f !important;
  /* font-family: inherit; */
}

.form-control-salud-beneficiario::placeholder {
  color: #cecece; /* Cambia "red" por el color que desees */
}

.formBeneficiario .btnSaludMas {
  border: none;
  font-size: 1.5rem !important;
  font-family: var(--fuente-bold);
  background-color: transparent;
}

.formBeneficiario .btnClose {
  border: none;
  background-color: transparent;
}

.formBeneficiario hr {
  width: 100%;
  margin-bottom: 10px;
}

.text-right {
  text-align: end;
  padding-right: 11rem;
}

.formBeneficiario .subtitulo_seccion {
  margin-right: 15rem;
  font-size: 16px;
}

.cmp-required {
  border-color: #ec111a !important;
}

.cmp-required-text {
  color: #ec111a !important;
}

@media screen and (max-width: 640px) {
  .formBeneficiario .subtitulo_seccion {
    width: 100%;
    font-size: 12px;
    margin-right: 0;
  }

  .preguntas__modal .titulo_seccion {
    padding-top: 20px;
  }

  .text-right {
    padding-right: 0;
  }
}

.titulo__salud {
  text-align: center;
  font-size: 1.2rem;
}

.titulo__salud h3 {
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  font-weight: 700;
  color: var(--coursera);
  font-size: 2.9rem;
  margin-bottom: 0;
}

.titulo__salud p {
  text-align: center;
  font-size: 1.2rem;
}

.formBeneficiario .preguntas__modal {
  margin: 3rem 2rem;
  color: black;
}

.formBeneficiario .titulo_infopersonal {
  font-size: 1.4rem;
  font-family: var(--fuente-bold);
  padding: 0;
  margin: 0;
}

.cuerpo_curp label {
  margin-top: 1rem;
  font-family: var(--fuente-bold);
  font-size: 1.2rem;
}

.cuerpo_curp .form-control-salud-beneficiario {
  max-width: 48%;
}

.cuerpo_curp .text-right {
  margin-top: 0.6rem;
  text-align: start;
  padding-left: 21.5rem;
}

.cuerpo_beneficios {
  margin-top: 4rem;
}
.cuerpo_beneficios .titulo_seccion,
.titulo_form {
  font-size: 1.4rem;
  font-family: var(--fuente-bold);
  padding: 0;
  margin: 0;
}

.cuerpo_beneficios .subtitulo_seccion {
  font-size: 1.2rem;
  margin: 0;
}

.fondo_campo {
  background-color: #eeeeee;
}

@media screen and (max-width: 784px) {
  .cuerpo_curp .form-control-salud-beneficiario {
    max-width: 96% !important;
  }
  .cuerpo_curp .text-right {
    padding-left: 0 !important;
    padding-right: 1.3rem !important;
    text-align: end !important;
  }

  .cuerpo_beneficios .subtitulo_seccion {
    margin: 0 !important;
    padding-right: 1rem;
  }
  .titulo__salud h3 {
    font-size: 3.4rem;
  }
}
