/* ***************************************** */
/* HEADER */
/* ***************************************** */
.home__opciones {
  display: flex;
  gap: 3rem;
}
.home__header {
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
  font-size: 1.8rem;
  background-color: #fff;

  /* position: relative; */
  z-index: 5;
}
.separador__izquierdo {
  padding-left: 1.5rem;
}

.home__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__botonesconfondo {
  padding: 1rem 3rem;
  background-color: var(--rojo);
  border: 1px solid var(--rojo);
  border-radius: 0.5rem;
  color: #fff;
  margin-right: 6rem;
  font-size: 1.4rem;
  font-weight: bold;
  width: 20rem;
  text-align: center;
}
.header__botones {
  padding: 1rem 3rem;
  border: 1px solid var(--rojo);
  border-radius: 0.5rem;
  color: var(--rojo);
  font-size: 1.4rem;
  font-weight: bold;
  width: 20rem;
  text-align: center;
}
.header__boton {
  border: 1px solid var(--rojo);
}
.header__nosotros {
  padding: 0 3rem;
  color: var(--texto);
  font-weight: 700;
  font-size: 1.4rem;
}
.activo {
  background-color: var(--rosado);
}
.activo div {
  color: red;
}
.header__text {
  color: var(--texto);
  font-weight: 700;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  letter-spacing: 1px;
}
.header__text-red {
  color: var(--rojo);
}
.header-opciones {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.header__selector {
  margin-left: 0.5rem;
  color: var(--rojo);
}

/* SECCION DEL MENU DE BENEFICIOS */

.header__opciones-beneficios {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -12rem;
  display: none;
  background: white;
  padding-bottom: 2rem;
}
.header__opciones-beneficios.mostrar {
  visibility: visible;
  opacity: 1;
  display: block;
  min-width: 45rem;
  text-align: left;
  margin-top: 4.8rem;
  padding-top: 2rem;
  box-shadow: 0px 3px 5px -1px #ccc;
  border: 1px solid var(--rojo);
  border-radius: 0.5rem;
  z-index: 2;
}
.header__opciones-beneficios span {
  padding-left: 2rem;
}
.header__title {
  padding-bottom: 1rem;
  display: block;
}
.header__opciones-beneficios li {
  width: 100%;
  text-align: left;
  padding: 0.5rem 0 0.5rem 0;
  border-style: none;
}
.header__opciones-beneficios li:hover {
  background-color: var(--rosado);
  color: var(--rojo);
}

/* SECCION DISCLAIMER */
.alert__wrapper{
  position: relative;
  top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.alert{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  width: 100%;
  position: absolute;
  background-color: #FFDF35B2;
  z-index: 1;
  margin: 0;
  border-radius: 0 !important;
  bottom: -43px;
}
.alert__closer{
  transition: 0.4s ease-out;
  opacity: 0;
  pointer-events: none;
}

.alert__icon{
  margin-left: 3rem;
  flex: 1;
}

.alert__text{
  font-size: 2rem;
  flex: 30;
  margin-top: 0.4rem;
}

.alert__close{
  cursor: pointer;
  flex: 1;
}

@media screen and (max-width: 991px) {
  .alert{
      position: fixed;
      width: 100vw;
      height: 100px;
      bottom: 0;
      left: 0;
      background-color: rgba(245, 236, 37, 0.9);
  }
  .alert__text{
      font-size: 1.2rem;
      flex: 6;
      margin-bottom: 1rem;
  }
  .alert__icon{
      transform: scale(1.2);
      margin: 0;
  }
  .alert__close{
      transform: scale(1.2);
      margin-left: 1rem;
  }
}

/* *** HEADER MOBILE */
.hamburger__container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid var(--texto-claro);
  color: #eee;
  padding: 2rem;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  font-weight: 900;
  z-index: 4;
}

.header__user {
  display: flex;
  align-items: center;
  
  height: 8rem;
  cursor: pointer;
  position: relative;
  padding: 0 5rem 0 0;
  gap: 5rem;
  color:var(--texto);
  font-weight: 700;
  font-size: 1.6rem;
}

.header__user-text {
  display: flex;
  align-items: center;
  color: var(--texto);
  gap: 1rem;
}
.header__user-text i {
  font-size: 2rem;
  color: var(--borde-form);
}

.header__user-detalle {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 12rem;
  display: none;
  background: white;
  color: var(--texto);
  cursor: default;
}
.header__user-detalle.mostrar {
  visibility: visible;
  opacity: 1;
  display: block;
  min-width: 25.8rem;
  text-align: center;
  margin-top: 8rem;
  border: 1px solid var(--texto-claro);
  border-radius: 0.5rem;
  z-index: 5;
  padding-top: 1rem;
}
.header__user-detalle i {
  font-size: 5.9rem;
}


@media screen and (max-width: 1100px) {
  .home__header {
    padding: 0 0 0 2rem;
  }
}

@media screen and (max-width: 920px) {
  .home__header {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .home__header {
    position: relative;
    padding: 0rem;
    justify-content: space-around;
    border-bottom: 1px solid var(--borde);
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  .header__botones{
    width: 13.4rem !important;
    padding: 1rem 1rem;
    margin-right: 3rem;
  }
  .header__botonesconfondo {
    margin-right: 2rem;
  }
  .home__header-hamburger {
    font-size: 0rem;
    padding: 0rem;
    color: #999;
    width: 0%;
    height: 0%;
  }

  .home__header-hamburger img{
    display: none;
  }

  .hamburger__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .hamburger__text {
    display: block;
    margin-top: 2rem;
    font-size: 1.9rem;
    color: var(--texto);
  }

  .separador__izquierdo {
    padding-left: 0rem;
  }

  .header__user-text i {
    font-size: 3.2rem;
  }

}

@media screen and (max-width: 640px) {
  .hamburger__container {
    position: fixed;
    top: 6rem;
    left: 0;
    padding: 2rem;
    font-size: 2rem;
  }

  .hamburger__text {
    font-size: 1.6rem;
  }

  .header__user {
    padding: 0 2rem 0 0;
  }

  .header__user-detalle {
    top: 0;
    left: 0;
    display: none;
  }
  .header__user-detalle.mostrar {
    min-width: 32.8rem;
    width: 100%;
    left: -20.6rem;
  }
  
}
