.container_emprendimiento-out {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6rem auto;
}
.container_emprendimiento-out img {
  width: 100%;
}
.container_emprendimiento-out h3 {
  font-size: calc(2rem + 1vw);
}

.container_emprendimiento-btn {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 6rem auto 3rem;
}

.emprendimiento-recuerda {
  width: 70%;
  margin: 7rem auto;
  box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.089);
  padding: 2rem 2rem 2rem 8rem;
  border-radius: 1rem;
  position: relative;
}
.emprendimiento_recuerda-decoracion {
  position: absolute;
  left: 2%;
  width: 26px;
  top: 0;
}
.emprendimiento-recuerda h2 {
  font-size: calc(1rem + 1vw);
  margin-bottom: 2rem;
}
.emprendimiento-recuerda p {
  font-size: 15px;
}

/* asesoria */
.container_asesoria h1 {
  text-align: center;
  font-size: calc(2rem + 1vw);
  margin-bottom: 3rem;
  margin-top: 10rem;
}
.container_asesoria-info ul {
  list-style: decimal !important;
}
.container_asesoria-info ul li {
  font-size: 17px;
  margin-bottom: 2rem !important;
  list-style: circle !important;
}

.container_asesoria-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}
.container_asesoria-info img {
  width: 100%;
}

/* coursera */

.container-universidades {
  width: 80%;
  margin: auto;
}
.container-universidades h1 {
  text-align: center;
  font-size: calc(2rem + 1vw);
  margin: 10rem auto 5rem;
}
.container-universidades h1 span {
  color: var(--celeste);
}

/* this is not applicable */
.universidades_carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10rem;
}

.beneficio-link {
  font-weight: 900 !important;
  color: var(--celeste);
  border-bottom: solid 1px var(--celeste);
}

.cursos_coursea {
  width: 80%;
  margin: auto;
  text-align: center;
}
.cursos_coursea h1 {
  font-size: calc(2rem + 1vw);
  margin-bottom: 2rem;
}
.cursos_coursea p {
  font-size: 17px;
}
.cursos_items {
  margin: 5rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .container_emprendimiento-out {
    width: 90%;
    flex-direction: column;
  }
  .container_emprendimiento-out h3 {
    text-align: center;
  }
  .emprendimiento-recuerda {
    padding: 2rem 2rem 2rem 6rem;
    width: 90%;
    margin-bottom: 10rem;
  }
  .emprendimiento_recuerda-decoracion {
    left: 4%;
  }

  /* asesoria */
  .container_asesoria-info {
    flex-direction: column;
  }

  /* this is not applicable */
  .universidades_carousel {
    flex-direction: column;
  }

  .cursos_items {
    flex-direction: column;
  }
}
