 .eco__textos-titlegrande {
   margin-top: 0rem;
   padding-top: 0rem;
   max-width: 60rem;
   font-size: 3.1rem;
   font-weight: 700;
   font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
 }

 .eco__textos-titlegrande.verde {
   color: var(--verde-btn-vida);
 }

 .eco__textos-titlegrande.morado {
   color: var(--morado);
 }

 @media screen and (max-width: 640px) {
   .eco__textos-titlegrande {
     font-size: 20px;
     text-align: center;
   }

 }