
.validador__container{
    margin-top: 1rem;
}
.auth__validador-resumen,
.validador__franjas{
    display:flex;
    gap: 1rem;
    justify-content:space-between;
}
.validador__franja{
    width: 6rem;
    height: .3rem;
    border-radius: 0.25rem;
    background-color: #4F5053;
}
.validador__franja.amarillo{
    /* background-color: var(--amarillo); */
    background-color: #F6D14E;
}
.validador__franja.rojo{
    /* background-color: var(--rojo); */
    background-color: #F6584E;
}
.validador__franja.verde{
    /* background-color: var(--verde); */
    background-color: #138468;
}
.validador__coment{
    font-weight: 400;
    color: var(--texto-validador);
}

.auth__validador-detalle{
    font-size: 12px;
    font-weight:400;
    color: var(--texto-validador);
    margin-top: 1rem;
}
.validador__detalle-item{
    display: flex;
    gap: 1rem;
    margin-top: .5rem;
    margin-left: 0.5rem;
}
.validador__detalle-item i.verde{
    color: var(--verde);
}
.validador__detalle-item i.plomo{
    color: var(--gris);
}

