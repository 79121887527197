.homeout__banner {
  margin-top: 4rem;
  width: 100%;
  height: 45rem;
  background-image: url("../../../../assets/img/img_oportunidades_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
}
.homeout__banner-texto {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.5rem);
  max-width: 45%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.homeout__banner-texto div p {
  font-size: 3.6rem;
  max-width: 50rem;
  font-weight: 700;
  margin-bottom: 1.6rem;
}

@media screen and (max-width: 768px) {
  .homeout__banner {
    height: 35rem;
    background-position: center;
  }
  .homeout__banner-texto{
    background-color: rgba(0, 0, 0, 0.5);
    max-width: 100%;
    padding: 0 3rem;
  }
  .banner__texto-title{
      font-size: 2.4rem;
      max-width: 100%;
      font-weight: 700;
  }
  .homeout__banner-texto div p {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    max-width: 100%;
    font-weight: 400;
  }
}
