:root {
    --selected-color: #7dc855;
    --unselected-color: #dfdfdf;
    --unselected-text-color: #b2b5ba;
}



.progress-bar-container{
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 90%;
}

.progress-bar{
    background-color: #ffffff ;
}

.progress-bar__bar{
    background-color: var(--unselected-color) ;
    align-self: center;
    flex-grow: 1;
    height: 4px;
}

.progress-bar__bar--completed{
    background-color: var(--selected-color);
    align-self: center;
    flex-grow: 1;
    height: 4px;
}

.progress-bar__circles{
    background-color: #ffffff ;
    display: flex;
}

.progress-bar__circle{
    background: #ffffff;
    border-radius: 50%;
    border: 3px solid var(--unselected-color);
    color: var(--unselected-text-color);
    cursor: pointer;
    height: 15px;
    line-height: 12px;
    position: relative;
    text-align: center;
    width: 15px;
}

.progress-bar__circle--completed{
    background: var(--selected-color);
    border-radius: 50%;
    border: 3px solid var(--unselected-color);
    color: var(--unselected-text-color);
    cursor: pointer;
    height: 15px;
    line-height: 12px;
    position: relative;
    text-align: center;
    width: 15px;
    border-color: var(--selected-color);
}

.numberC {
    color: #fff;
    font-size: 1.5em;
}

.progress-bar__circle--selected{
    background: var(--selected-color);
    border-radius: 50%;
    border: 3px solid var(--unselected-color);
    color: var(--selected-color);
    cursor: pointer;
    height: 15px;
    line-height: 12px;
    position: relative;
    text-align: center;
    width: 15px;
    border-color: var(--selected-color);
}

.numberN {
    font-size: 0.8em;
    font-weight: bold;
}

.progress-bar__circle__label{
    background: var(--selected-color);
    border-radius: 50%;
    border: 3px solid var(--unselected-color);
    cursor: pointer;
    height: 15px;
    line-height: 12px;
    top: 100%;
    color: var(--unselected-text-color);
    font-weight: 600;
    left: 50%;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: -50px;
    position: absolute;
    text-align: center;
    width: 100px;
}

.progress-bar__circle__label{
    font-size: 11px;
    color: #5e5e5e;
}