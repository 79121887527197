/* ***************************************** */
/* OUT ECOSISTEMA PRESENTACION */
/* ***************************************** */

.eco__presentacion {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 40rem;
  margin-bottom: 2rem;
  gap: 5rem;
}

.eco__presentacion-mini {
  display: flex;
  justify-content: center;
  position: relative;
}

.presentacion__migaja {
  position: absolute;
  top: 0rem;
  left: 15rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--texto);
}

.eco__presentacion-textos .presentacion__out-migaja {
  position: absolute;
  top: 0;
  width: 75%;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--texto);
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  font-size: 2rem;
  gap: 1rem;
}

.migaja__texto {
  display: flex;
  align-items: center;
}

.migaja__link {
  color: var(--texto);
  margin: 0 1rem;
  font-size: 1.4rem;
}

.migaja__link:hover {
  font-weight: bold;
}

/* ********************************* */
/* ZONA SUPERIOR */
/* ********************************* */
.fondoblanco {
  background: linear-gradient(to right, #fff 70%, transparent);
  z-index: 1;
}

.eco__presentacion-img,
.eco__presentacion-textos {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.eco__presentacion-textos.altura {
  margin-top: 2rem;
  min-height: 10rem;
  height: auto;
}

.eco__presentacion-img {
  flex: 6;
  margin-right: 7rem;
}

.eco__presentacion-textos {
  flex: 7;
  padding-top: 0;
}

.eco__presentacion-textos.izquierda {
  padding-left: 3rem;
  margin-top: 7rem;
}

.eco__detalle-titulo {
  font-size: 3.6rem;
  text-align: center;
  font-weight: 700;
  color: var(--texto-dark);
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
}

.eco__detalle-titulo.centro {
  font-size: 3.2rem;
  text-align: center;
  font-weight: 700;
  color: var(--texto-dark);
}

.eco__detalle-subtitulo {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 400;
  color: var(--texto);
  max-width: 60rem;
  margin: 0 auto 6rem;
}

.eco__detalle-subtitulogrande,
.eco__detalle-subtitulopequeno {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 400;
  color: var(--texto);
  max-width: 100rem;
  margin: 3rem auto 0;
}

.eco__detalle-subtitulo-salud {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--texto);
  max-width: 80%;
  margin: 0 auto 6rem;
}

.eco__textos-title {
  font-size: 3.2rem;
  font-weight: 700;
  color: var(--texto-dark);
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  width: 80%;
}

.ecoout__textos-titlegrande {
  margin-top: 2rem;
  max-width: 48rem;
  font-size: 5.5rem;
  font-weight: 700;
}

.eco__textos-title .azul {
  color: var(--azul);
}

.eco__textos-title .verde {
  color: var(--verde);
}

.eco__textos-title .naranja {
  color: var(--naranja);
}

.eco__textos-title .morado {
  color: var(--morado);
}

.eco__textos-title .celeste {
  color: var(--celeste);
}

.eco__textos-title .naranja-claro {
  color: var(--naranja-claro);
}

.eco__textos-title .morado-claro {
  color: var(--morado-claro);
}

.eco__textos-subtitle {
  font-weight: 700;
  font-size: 5.5rem;
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
}

.eco__textos-subtitle.azul {
  color: var(--azul);
}

.eco__textos-subtitle.naranja {
  color: var(--naranja);
}

.eco__textos-subtitle.verde {
  color: var(--verde);
  margin-top: 20px;
}

.eco__textos-subtitle.verde-gris {
  color: var(--verde-gris);
}

.eco__textos-subtitle.naranjo-rosado {
  color: var(--naranjo-rosado);
}

.eco__textos-subtitle.morado {
  color: var(--morado);
}

.eco__textos-subtitle.celeste {
  color: var(--celeste);
  margin-top: 20px;
}

.eco__textos-subtitle.naranja-claro {
  color: var(--naranja-claro);
}

.eco__textos-subtitle.morado-claro {
  color: var(--morado-claro);
}

.eco__textos-subtitle.naranja2 {
  color: var(--naranja2);
}

.eco__textos-descrip {
  font-weight: 700;
  color: var(--texto);
  font-size: 1.8rem;
  width: 80%;
}

.eco__textos-descripmini {
  font-weight: 400;
  color: black;
  font-size: 1.7rem;
  margin-top: 2rem;
  width: 35vw;
}

.eco__textos-descripmini span {
  font-family: 'Scotia-Bold';
}

.eco__textos-descripmini-bottom {
  font-weight: 400;
  color: var(--texto);
  font-size: 14px;
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  margin-top: 1rem;
  width: 40vw;
  padding-right: 6rem;
  line-height: 28px;
}

.lista__alertas {
  padding-top: 0.5rem;
}

.alertas__body,
.proteccion__body,
.salarios__body {
  font-size: 1.5rem;
  text-align: start;
  padding-top: 5rem;
}

.salarios__body {
  font-size: 1.7rem;
}

.alertas__body p,
.salarios__body label {
  font-family: 'Scotia-Bold';
}

.alertas__form input,
.alertas__form select,
.salarios__form input,
.salarios__form select {
  width: 100%;
  padding: 1rem;
  border-radius: 5px !important;
  border: 1px solid #A7A7A7;
  font-size: 15px;
}

.alertas__form input:disabled,
.alertas__form select:disabled {
  background-color: #EEEEEE;
  color: #8D8D8D;
}

.salarios__form input,
.salarios__form select {
  height: 4rem;
}

.alertas__form select {
  height: 4.5rem;
}

.proteccion-online__beneficios {
  font-size: 17px;
}

.div__lista-proteccion p {
  display: list-item;
  list-style-type: initial;
  margin-left: 1em;
}

.presentacion-img-in {
  display: flex;
}

.eco__presentacion-img img {
  max-width: 100%;
  object-fit: cover;
  height: 36rem;
  float: inline-end;
}

.eco__presentacion-textos.verde {
  background-color: var(--verde);
}

.eco__presentacion-img.verde {
  background-color: var(--verde-claro);
}

.eco__presentacion-img.verde img {
  width: 100%;
}

.eco__presentacion-textos.naranja {
  background-color: var(--naranja);
}

.eco__presentacion-img.naranja {
  background-color: var(--naranja-claro2);
}

.eco__presentacion-img.naranja img {
  width: 100%;
}

.eco__presentacion-textos.morado {
  width: 100%;
  background-color: var(--morado);
}

.eco__presentacion-img.morado {
  background-color: var(--morado-claro2);
}

.eco__presentacion-img.morado img {
  width: 100%;
}

.eco__presentacion-textos.azul {
  background-color: var(--azul);
}

.eco__presentacion-img.azul {
  background-color: var(--azul);
}

.eco__presentacion-img.azul img {
  width: 100%;
  object-fit: cover;
}

.eco__presentacion-textos.celeste {
  background-color: var(--celeste);
}

.eco__presentacion-img.celeste {
  background-color: var(--celeste);
}

.eco__presentacion-img.celeste img {
  width: 100%;
  object-fit: cover;
}

.eco__presentacion-textos.naranja-claro {
  background-color: var(--naranja-claro);
}

.eco__presentacion-img.naranja-claro {
  background-color: var(--naranja-claro);
}

.eco__presentacion-img.naranja-claro img {
  width: 100%;
  object-fit: cover;
}

.eco__presentacion-textos.morado-claro {
  background-color: var(--morado-claro);
}

.eco__presentacion-img.morado-claro {
  background-color: var(--morado-claro3);
}

.eco__presentacion-img.morado-claro img {
  width: 100%;
  object-fit: cover;
}

.eco__presentacion-img div {
  display: flex;
}

.presentacionTexto__etapa {
  display: flex;
  margin-top: 2rem;
  font-size: 1.6rem;
}

.etapa__proceso {
  padding: 0.5rem 1.1rem;
  border-radius: 50%;
  font-weight: bold;
  border: 2px solid #fff;
}

.etapa__proceso_hoja {
  padding: 0.5rem 1.1rem;
  border-radius: 15px;
  font-weight: bold;
  border: 2px solid #fff;
}

.etapa__linea {
  width: 2rem;
  height: 0.2rem;
  background-color: #fff;
  margin: auto 0;
}

/* .eco__presentacion-textos div p:nth-child(1) {
  margin-top: 2rem;
}
.eco__presentacion-textos div p {
  font-size: 1.6rem;
  margin-bottom: 1rem;
} */

/* ***************************************** */
/* OUT ECOSISTEMA CARDS */
/* ***************************************** */
.eco__unirme {
  text-align: center;
}

.eco__sombreado h3,
.eco__sombreado h1 {
  text-align: center;
  font-weight: 700;
  font-size: 4.4rem;
  margin-top: 5rem;
}

.eco__sombreado>p {
  /* text-align: center; */
  font-weight: 700;
  font-size: 2rem;
  margin-top: 1rem;
  margin-left: 10px;
  color: var(--texto);
}

.eco__cards {
  margin: 3rem auto;
  width: 80%;
  color: var(--texto);
}

.eco__cards h2 {
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
}

/* .eco__cards h2{
  font-size: ;
} */

.eco__cards-in {
  margin-top: 3rem;
  width: 100%;
  color: var(--texto);
}

.eco__cards h2 {
  font-weight: 700;
  font-size: 3.6rem;
  color: var(--texto-dark);
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.eco__cards>p {
  font-size: 1.6rem;
  margin-bottom: 4rem;
}

.eco__card {
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 2px 1px #4462f51c !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 2rem;
  max-width: 318px;
  min-height: 417px;
}

.cupones__cards {
  margin-bottom: 3rem;
}

.cupones__cards .alice-carousel__stage {
  left: 4px;
}

.card__cupon {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 8%);
  border-radius: 12px;
  min-height: 380px;
  max-width: 313px;
  justify-content: space-between;
}

.card__cupon-img {
  height: 17.6rem;
  position: relative;
  width: 100% !important;
}

.card__cupon-img>img {
  border-radius: 13px 13px 0 0;
  height: 100%;
  object-fit: cover;
  object-position: right center;
  width: 100%;
}

.card__cupon-ico_container {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  display: flex;
  height: 54px;
  justify-content: center;
  padding: 0.5rem;
  position: absolute;
  width: 58px;
}

.card__cupon-ico_container>img {
  background: none;
  bottom: 0;
  height: 52px;
  left: 0;
  margin: 0;
  object-fit: contain;
  object-position: center;
  padding: 0;
  position: relative;
  width: 52px;
}

.card__cupon-texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 1.5rem 0;
}

.card__cupon-superior>h4 {
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  font-size: 18px;
  text-align: left;
}

.card__cupon-superior>p {
  font-size: 14px;
  text-align: left;
}

.card__cupon-descuento,
.modal__cupon-descuento {
  position: absolute;
  top: 12px;
  left: -5px;
  width: 64px;
  height: 40px;
  background-image: url('data:image/svg+xml,%3Csvg width="64" height="40" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 17L5 23V39.5L0 32V17Z" fill="%23E96D41"/%3E%3Cpath d="M0 0H48C56.8366 0 64 7.16344 64 16V32H0V0Z" fill="%23E96D41"/%3E%3C/svg%3E');
}

.card__cupon-descuento>p {
  text-align: center;
  width: 70%;
  padding: 6px;
  margin: auto;
  color: #ffffff;
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  font-size: 16px;
}

.div__cupones {
  text-align: center;
  max-width: 1400px;
  margin: auto;
}

.div__cupones>h2 {
  text-align: left;
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  margin-bottom: 2rem;
}

.div__mas-cupones {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding-bottom: 9rem;
}

.modal__cupon-window .modal-content,
.modal__cupon-trimestral-window .modal-content {
  border-radius: 20px;
}

.modal__cupon {
  padding: 2rem;
}

.modal__cupon h2 {
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  font-size: 24px;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.modal__cupon p {
  font-size: 14px;
}

.modal__cupon b {
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
}

.modal__cupon-codigo {
  background-color: var(--naranja2);
  color: #ffffff;
  border-color: var(--naranja2);
  padding: 1.5rem 2rem;
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  font-size: 15px;
  border-radius: 5px;
  width: 440px;
  margin: 2rem 0rem;
}

.modal__cupon-img {
  position: relative;
}

.modal__cupon-img>img {
  border-radius: 20px;
  object-fit: cover;
  object-position: right center;
  height: 160px;
  width: 100%;
}

.modal__cupon-ico {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  display: flex;
  height: 72px;
  justify-content: center;
  padding: 0.5rem;
  position: absolute;
  width: 72px;
}

.modal__cupon-ico>img {
  background: none;
  bottom: 0;
  height: 72px;
  left: 0;
  margin: 0;
  object-fit: contain;
  object-position: center;
  padding: 0;
  position: relative;
  width: 72px;
}

.modal__cupon-descuento {
  position: absolute;
  top: 20px;
  left: -15px;
  width: 92px;
  height: 55px;
  background-image: url('data:image/svg+xml,%3Csvg width="92" height="55" viewBox="0 0 92 55" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 11L16 22.7333V55L0 40.3333V11Z" fill="%23E96D41"/%3E%3Cpath d="M0 0H60C77.6731 0 92 14.3269 92 32V40H0V0Z" fill="%23E96D41"/%3E%3C/svg%3E');
}

.modal__cupon-descuento>p {
  text-align: center;
  width: 70%;
  padding: 6px;
  margin: auto;
  color: #ffffff;
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  font-size: 20px;
}

.flag__usar {
  border-radius: 4px;
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  background-color: gray;
  padding: 5px 10px;
  margin-top: 2.5rem;
  width: 204px;
  font-size: 13px;
}

.modal__cupon-trimestral-solicitado {
  padding: 5rem 2rem 4rem 2rem;
  text-align: center;
}

.modal__cupon-trimestral-solicitado>h1 {
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  padding-bottom: 1rem;
  text-align: left;
}

.modal__cupon-trimestral-solicitado>p {
  font-size: 15px;
  text-align: left;
  padding-bottom: 4rem;
}

.userin__container {
  padding: 3rem 2rem;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 3px 5px 10px rgba(68, 97, 245, 0.17);
}

.userin__container>h4 {
  font-size: 3.6rem;
  font-weight: 700;
  color: var(--texto);
}

.userin__container-texto {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--texto);
  margin: 1rem 0;
}

.userin__container-subtexto {
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--texto);
  margin: 0rem 0;
}

.user_card-eco {
  display: flex;
  align-items: flex-start;
  font-size: 1.4rem;
  gap: 1rem;
  margin: 5px 0;
  cursor: pointer;
}

.user_card-img {
  width: 1.5rem;
  height: 1.5rem;
}

.user_card-text {
  font-size: 1.5rem;
  width: 20rem;
  font-weight: 400;
}

.eco__card-img {
  height: 206.33px;
  width: 100% !important;
  position: relative;
}

.eco__card-icoprincipal {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 2rem 2rem 0 0;
}

.eco__card-ico_container {
  position: absolute;
  bottom: -2.7rem;
  left: 2rem;
  width: 58px;
  height: 54px;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0px 4px 16px rgba(69, 91, 99, 0.14);
}

.eco__card-ico_container .eco__card-ico {
  /* icono container QA15/081 */
  position: relative;
  bottom: 0rem;
  width: 24px;
  height: 24px;
  left: 0rem;
  border-radius: 0rem;
  background: none;
  padding: 0px;
  margin: 0px;
  object-fit: contain;
  object-position: center;
}

.eco__card-ico {
  position: absolute;
  bottom: -2.5rem;
  left: 1rem;
  width: 5rem;
  height: 5rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.5rem;
  object-fit: contain;
  object-position: center;
  /* box-shadow: 0px 4px 16px rgba(69, 91, 99, 0.14); */
  /* QA15/18 */
}

.eco__card-texto {
  padding: 2.5rem 2.5rem 0 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.eco__card-superior-imgs {
  width: 100%;
  height: 4rem;
  margin: 5px 2px 10px 0;
  object-fit: contain;
}

.eco__card-superior-imgs img {
  height: 100%;
}

.eco__card-superior-imgs img.verde {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

.eco__card-superior-imgs img.naranja {
  filter: invert(49%) sepia(73%) saturate(2702%) hue-rotate(341deg) brightness(101%) contrast(97%);
}

.eco__card-superior-imgs img.morado {
  filter: invert(37%) sepia(11%) saturate(5632%) hue-rotate(235deg) brightness(85%) contrast(89%);
}

.eco__card-superior-imgs img.azul {
  filter: invert(28%) sepia(34%) saturate(5971%) hue-rotate(186deg) brightness(88%) contrast(105%);
}

.eco__card-superior-imgs img.celeste {
  filter: invert(37%) sepia(72%) saturate(1091%) hue-rotate(162deg) brightness(107%) contrast(101%);
}

.eco__card-superior-imgs img.naranja-claro {
  filter: invert(61%) sepia(7%) saturate(2720%) hue-rotate(318deg) brightness(90%) contrast(84%);
}

.eco__card-superior-imgs img.morado-claro {
  filter: invert(66%) sepia(5%) saturate(3741%) hue-rotate(194deg) brightness(81%) contrast(96%);
}

.eco__card-title {
  color: var(--texto-dark);
  font-size: 2rem;
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  align-content: center;
  min-height: 52px;
  /* font-family: 'Scotia-Headline'; */
  /* DANGER */
}

.eco__card-title.verde {
  color: var(--verde);
}

.eco__card-title.verde-gris {
  color: var(--verde-gris);
}

.eco__card-title.naranjo-rosado {
  color: var(--naranjo-rosado);
}

.eco__card-title.naranja {
  color: var(--naranja);
}

.eco__card-title.morado {
  color: var(--morado);
}

.eco__card-title.azul {
  color: var(--azul);
}

.eco__card-title.celeste {
  color: var(--celeste);
}

.eco__card-title.naranja-claro {
  color: var(--naranja-claro);
}

.eco__card-title.morado-claro {
  color: var(--morado-claro);
}

.eco__card-title.naranja2 {
  color: var(--naranja2);
}

.eco__card-subtitulo {
  font-size: 1.6rem;
  margin: 1rem 0 2rem;
  padding-right: 2rem;
  color: var(--texto);
  margin-top: 4rem;
  font-weight: 700;
  /* font-family: 'Scotia-Headline'; */
}

.eco__card-descripcion {
  font-size: 1.35rem;
  line-height: 18px;
  color: var(--texto);
  font-weight: 400;
}

.eco__card-btn,
.card__cupon-button {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 3rem;
}

.eco__card-btn {
  margin-left: 2.5rem;
  justify-content: start !important;
}

.card__cupon-button>button {
  background-color: var(--naranja2);
  color: #ffffff;
  font-size: 16px;
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  text-decoration: none;
  border: 1px solid var(--naranja2);
  padding: 1.2rem 4.5rem;
  border-radius: 5px;
}

.eco__card-btn-in {
  display: flex;
  justify-content: flex-end;
  margin-top: 5rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  font-weight: normal;
}

.space {
  margin-top: 10px;
}

/* ***************************************** */
/* OUT ECOSISTEMA CARDS */
/* ***************************************** */
.ecosistemas__cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
  gap: 2rem;
}

.ecosistemas__cards-in {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  gap: 5rem;
  justify-items: center;
}

/* ************************************************* */
/* ECOSISTEMA - COMIENZA PROCESO */
/* ************************************************* */
.ecosistema__comienza {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  border-top: 1px solid var(--texto-claro);
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}

.ecosistema__comienza p {
  margin-top: 3rem;
  font-size: 1.6rem;
}

.comienza__btns {
  margin: 6rem auto;
}

/* ************************************************* */
/* ECOSISTEMA - RESUMEN */
/* ************************************************* */

.ecosistema__resumen {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 6rem;
}

.ecosistema__resumen h3 {
  font-size: 3.2rem;
  line-height: 1.2;
  color: var(--verde);
  margin-top: 6rem;
  margin-bottom: 3rem;
  text-align: center;
}

.ecosistema__resumen p {
  font-size: 1.6rem;
  line-height: 1.8;
  margin-bottom: 4rem;
}

/* ************************************************* */
/* PRESENTACION - INICIA ECOSISTEMA */
/* ************************************************* */
.ecosistema__inicia {
  position: relative;
  margin-top: -0rem;
  margin-bottom: 8rem;
}

.ecosistema__inicia-text {
  text-align: center !important;
  width: 90%;
  margin: auto;
}

.ecosistema__inicia-text h1 {
  font-size: 30px;
  margin: 3rem auto;
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
}

.ecosistema__inicia-text p {
  font-size: 17px;
}

.ecosistema__inicia-subititulo {
  margin: 5rem auto 0rem;
}

.ecosistema__inicia-subititulo.morado {
  background-color: #e1d6ef;
}

.ecosistema__inicia-subititulo.verde {
  background-color: #cdfff9;
}

.ecosistema__inicia-subititulo.morado-claro-fondo {
  background-color: var(--morado-claro-fondo);
}

.ecosistema__inicia-subititulo.naranja-claro-fondo {
  background-color: #ffe8e1;
}

.ecosistema__inicia-subititulo.naranja-fondo {
  background-color: var(--naranja-fondo);
}

.ecosistema__inicia-subititulo.azul-fondo {
  background-color: var(--azul-fondo);
}

.ecosistema__inicia-subititulo.celeste-fondo {
  background-color: var(--celeste-fondo);
}

.inicia__subtitulo {
  width: 70%;
  padding: 5rem;
  margin: auto;
  line-height: 1.8;
}

.inicia__subtitulo h2 {
  font-size: calc(2rem + 1vw);
}

.inicia__subtitulo-title {
  font-size: calc(3.5rem + 0.5vw);
  font-weight: 700;
  color: var(--texto-dark);
  text-align: center;
}

.inicia__subtitulo-detalle {
  font-size: 1.8rem;
  text-align: center;
}

.inicia_life-informacion>p {
  margin-top: -2px !important;
}

.inicia_life-extra {
  padding-top: 5rem !important;
}

.ecosistema__inicia-subititulo.verde .inicia__subtitulo h3 {
  color: var(--verde);
}

.inicia__subtitulo h3 {
  font-size: 3.2rem;
  font-weight: bold;
}

.inicia__subtitulo h4 {
  margin-top: 2rem;
  font-size: 2.2rem;
  font-weight: bold;
}

.inicia__subtitulo p {
  font-size: 1.8rem;
  margin-top: 2rem;
}

.inicia__subtitulo .desktop img {
  margin-bottom: auto;
}

.inicia__subtitulo img {
  height: 18rem;
}

.inicia__subtitulo-registro {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  gap: 2rem;
}

.inicia__subtitulo-cards {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 1rem;
}

.inicia__subtitulo-card {
  height: 13rem;
  width: 13rem;
  border: 1px solid #ccc;
  text-align: center;
  padding: 1rem;
  box-shadow: 3px 3px 10px #ccc;
}

.inicia__subtitulo-card img {
  height: 5rem;
}

.inicia__subtitulo-card div {
  font-size: 1.4rem;
}

.ecosistema__inicia-bloque {
  margin: 4rem auto;
  width: 75%;
  color: var(--texto);
  background-color: #fff;
  padding: 4rem 3rem;
}

.ecosistema__inicia-bloque .textComentTitle {
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
}

.ecosistema__inicia-bloque hr {
  border-color: #d6dce8;
}

.ecosistema__inicia-bloque .number {
  font-weight: bold;
}

.ecosistema__inicia-bloque .textComent .form-control2 {
  height: 60px;
}

.ecosistema__inicia-bloquescore {
  margin: 0 auto;
  width: 85%;
  color: var(--texto);
  background-color: #fff;
  padding: 4rem 3rem;
}

.ecosistema__inicia-bloque-job {
  margin: 0 auto;
  width: 80%;
  color: var(--texto);
  background-color: #fff;
  border: 1px solid var(--texto-claro);
  padding: 4rem 3rem;
}

.ecosistema__inicia-bloque-job1 {
  margin: 0 auto;
  width: 50%;
  color: var(--texto);
  background-color: #fff;
  border: 1px solid var(--texto-claro);
  padding: 4rem 3rem;
}

.ecosistema__bloque-img {
  display: flex;
  justify-content: center;
  height: 10rem;
  margin-bottom: 2rem;
}

.ecosistema__inicia-bloque img {
  height: 100%;
}

.ecosistema__inicia-bloque p {
  font-size: 1.7rem;
  margin-bottom: 2rem;
}

.ecosistema__inicia-bloque h2 {
  margin-top: 3rem;
  font-size: 2.3rem;
}

.ecosistema__inicia-bloque2 h2 {
  margin-top: 3rem;
  font-size: 2.3rem;
  text-align: center;
}

/* Bloque */
.ecosistema__form-campos {
  display: flex;
  justify-content: space-around;
  gap: 3rem;
  width: 70%;
  margin: 0 auto;
}

.ecosistema__form-group {
  /* background-color: #123; */
  text-align: center;
  width: 100%;
}

.ecosistema__form-group p {
  margin: 0;
  font-weight: bold;
}

.ecosistema__form-group input {
  width: 100%;
  padding: 1rem;
  color: var(--texto);
  border: 1px solid var(--texto-claro);
}

.ecosistema__form-check {
  display: flex;
  justify-content: center;
}

.ecosistema__ul {
  display: flex;
  align-items: space-between;
}

.ecosistema__ul li {
  flex: 1;
  margin-top: 1rem;
  font-size: 1.6rem;
  list-style-type: circle !important;
  margin-left: 1.5rem;
}

.ecosistema__ulsimple li {
  margin-top: 1rem;
  font-size: 1.6rem;
  list-style-type: circle;
  margin-left: 1.5rem;
}

.ecosistema__inicia-btn {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.ecosistema_boton {
  padding: 2rem 8rem;
  font-size: 1.8rem;
  border-radius: 4rem;
  margin: 4rem 0;
}

.ecosistema__separadortop {
  width: 90%;
  padding: 2.5rem;
  border-bottom: 1px solid var(--texto-claro);
  margin: 0 auto;
}

/* BOTON CHECK PERSONALIZADO */
.content-input input,
.content-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.content-input input {
  visibility: hidden;
  position: absolute;
  right: 0;
}

.content-input {
  position: relative;
  margin-bottom: 30px;
  padding: 5px 0 5px 60px;
  /* Damos un padding de 60px para posicionar 
        el elemento <i> en este espacio*/
  display: block;
}

.content-input input+i {
  background: #f0f0f0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
}

.content-input input[type="checkbox"]+i {
  width: 52px;
  height: 30px;
  border-radius: 15px;
}

/* boton interno del input */
.content-input input[type="checkbox"]+i:before {
  content: "";
  /* No hay contenido */
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  -webkit-box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.2);
}

/* Acciones del mouse */
.content-input input[type="checkbox"]:checked+i:before {
  left: 22px;
  -webkit-box-shadow: -3px 0 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 3px 0 -3px 0 rgba(0, 0, 0, 0.2);
}

.content-input input[type="checkbox"]:checked+i {
  /* background: var(--rojo); */
  background: #44a246;
}

.content-inputRojo input[type="checkbox"]+label {
  font-weight: bold;
  line-height: 3em;
  color: #ccc;
}

.content-inputRojo input[type="checkbox"]:checked+label {
  color: #0fbf12;
}

/* ************************************************* */
/* AGENDAMIENTO */
/* ************************************************* */
/* ************************************************* */
/* FORMULARIO CONTACTO */
/* ************************************************* */
.eco__textos-titlegrande_d {
  font-size: 5.5rem;
  max-width: 50rem;
}

.ecosistema__contacto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ecosistema__contacto .ecocontacto__title {
  margin-top: 2rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--texto);
}

.ecocontacto__agrupador,
.ecocontacto__agrupador-final {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1.5rem;
}

.ecocontacto__agrupador-final {
  min-width: 30rem;
  color: #7dc855;
  padding: 1.5rem 3rem;
}

.ecocontacto__agrupador-final i {
  margin-right: 1rem;
  font-size: 2rem;
}

.ecocontacto__agrupador-final p {
  font-size: 1.6rem;
}

.ecocontacto__agrupador input {
  padding: 1rem 2rem;
  border: none;
  outline: none;
  min-width: 25rem;
}

.ecocontacto__agrupador input {
  background-color: #fff;
}

.ecocontacto__agrupador button {
  padding: 1rem 2rem;
  border-radius: 1rem;
  border: none;
  outline: none;
}

.agenda__confirmacion-subtitle {
  text-align: center;
  color: var(--confirmacion);
  margin: 0 !important;
}

/* ************************************************* */
/* FORMULARIO CONTACTO */
/* ************************************************* */

.contorno {
  /* border: 1px solid var(--color-claro); */
  box-shadow: 0 0 3px #ccc;
  padding-bottom: 3rem;
}

.homeout__checks {
  margin-top: 8rem;
}

.homeout__checks {
  display: flex;
  justify-content: space-around;
}

.homeout__listDiv>div {
  display: flex;
  justify-content: space-between;
}

.item__check {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}

.item__check img {
  margin-right: 1rem;
}

/* ************************************************* */
/* AGENDAMIENTO_OK */

.agendamiento__container-alerta-ok {
  width: 70%;
  padding: 3rem !important;
  margin: 3rem auto;
  text-align: center;
}

.agendamiento__container-alerta-ok h1 {
  font-size: calc(2rem + 1vw);
  margin-bottom: 2rem;
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
}

.agendamiento__container-alerta-ok p {
  font-size: 17px;
  margin-bottom: 4rem;
}

.form-control-salud-agenda,
.form-control-valor-factura {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0.375rem 0.75rem;
  border-radius: 4px !important;
  border: solid 1px #bababa !important;
  background-color: #ffffff !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;

  /* font-family: inherit; */
}

.asyncselect-salud-agenda,
.asyncselect-codigoPostal-buro {
  display: block;
  width: 100%;
  height: 45px;
  border-radius: 4px !important;
  border: solid 1px #bababa !important;
  background-color: #ffffff !important;
}

.asyncselect-codigoPostal-buro {
  border: solid 1px #A7A7A7 !important;
  border-radius: 5px !important;
}

.form-control-salud-agenda:invalid,
.form-control-valor-factura:invalid {
  color: #a7a7a7;
}

.form-control-salud-agenda>option,
.form-control-valor-factura>option {
  color: var(--texto);
}

.form-control-salud-agenda.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23009dd6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-control-valor-factura.form-select {
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 2L9 8L2 2' stroke='%23E96D41' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position-x: 96%;
}

.form-control-salud-gray {
  background-color: #f7f7f7 !important;
}

/* forms checkup anual */

.form-estado-ubicacion[disabled] {
  background-color: #e0e0e0 !important;
  color: #808080;
  cursor: not-allowed;
}

/* fin checkup anual */

.agenda__resumen-descrip-salud {
  font-size: 1.3rem;
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
}

.tiene__cita {
  background-color: #eeeeee !important;
}

.body__eco__detalle {
  text-align: center;
  margin: auto;
  max-width: 780px;
  min-height: 500px;
}

.body__eco__detalle p {
  text-align: left;
  font-size: 15px;
}

.div__resultado__busqueda {
  padding-top: 6rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.div__resultado__busqueda a {
  text-decoration: underline;
}

.container__buscador {
  display: flex;
  gap: 3rem;
  width: 85%;
}

.container__buscador select:focus {
  color: var(--naranja2);
  border-color: var(--naranja2) !important;
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8L8.5 2L15.5 8' stroke='%23E96D41' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  box-shadow: none;
}

.legal__consultar {
  padding-top: 3rem;
  max-width: 600px;
}

.legal__consultar label {
  font-family: 'Scotia-Bold';
  font-size: 1.6rem;
}

.legal__consultar textarea {
  width: 100%;
  height: 87px;
  padding: 1rem;
  font-size: 1.6rem;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
}

.div__imagen-fractalia img {
  max-width: 60%;
}

/* ************************************************* */

/* ************************************************* */
/* MEDIA QUERY */
/* ************************************************* */

@media screen and (max-width: 1200px) {
  .eco__presentacion-textos.izquierda {
    padding-left: 0;
  }
}

@media screen and (max-width: 992px) {
  .eco__presentacion.sinfondoresponsive {
    background-size: initial !important;
  }

  .eco__presentacion-textos.izquierda {
    padding-left: 0;
    margin-top: 7rem;
  }
}

@media screen and (max-width: 764px) {
  .fondoblanco {
    background: none;
    margin: 0rem;
  }

  .eco__textos-descripmini {
    width: 100%;
    padding: 0;
    text-align: left;
    font-size: 1.4rem;
  }

  .eco__presentacion-textos.izquierda {
    margin-top: 2rem;
  }

  .alertas__body {
    padding-top: 0;
  }

  .alertas__body {
    font-size: 14px;
  }

  .salarios__body {
    font-size: 1.6rem;
    padding-top: 0;
  }

  .addpadding {
    padding-top: 30px;
  }

  .eco__cards {
    margin: 6rem auto;
    padding: 0 1rem;
    width: 100%;
  }

  .eco__cards h2 {
    font-size: 2.4rem;
    margin: 1rem 3rem 3rem;
  }

  .ecosistemas__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    gap: 2rem;
  }

  .eco__card {
    background-color: #fff;
  }

  .eco__card-superior-imgs {
    height: 3rem;
  }

  .eco__card-texto {
    padding: 4.5rem 2.5rem 0 2.5rem;
  }

  .eco__card-title {
    font-size: 2.1rem;
    padding-right: 0rem;
    text-align: left !important;
  }

  .eco__card-btn-in {
    margin: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .eco__presentacion {
    flex-direction: column-reverse;
    min-height: auto;
    margin-bottom: 0;
  }

  .eco__presentacion.sinfondoresponsive {
    background: none !important;
  }

  .eco__presentacion-img img {
    width: 100%;
    height: 18rem;
    margin-top: 4rem;
  }

  .eco__presentacion-textos {
    margin: 0;
    padding: 0rem 3rem 0 3rem;
  }

  .eco__detalle-titulo {
    margin-top: 0;
  }

  .eco__presentacion-textos.altura {
    margin-top: 0;
  }


  .ecosistema__inicia-bloque {
    margin: 2rem auto;
  }

  .ecosistema__inicia-bloque h2 {
    margin-top: 0rem;
  }

  .eco__presentacion-textos.izquierda {
    padding-left: 0rem;
  }

  .ecosistema__comienza p {
    margin-top: 3rem;
    font-size: 1.2rem;
  }

  .ecosistema__resumen h3 {
    font-size: 2rem;
    /* text-align: start; */
  }

  .ecosistema__resumen p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .ecosistema__inicia {
    position: relative;
    margin-top: 0rem;
  }

  .ecosistema__inicia-bloque {
    border: none;
    padding: 0 3rem;
  }

  .ecosistema__inicia-bloque {
    width: 100%;
  }

  /* .ecosistema__inicia-bloque p {
    font-size: 1.2rem;
  } */

  .ecosistema_boton {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    /* border-radius: 4rem; */
    margin: 3rem 0;
  }

  .ecosistema__inicia-bloque h2 {
    /* margin-top: 3rem; */
    font-size: 1.4rem;
  }

  .ecosistema__ulsimple li {
    font-size: 1.2rem;
  }

  .agendamiento__container {
    margin-top: 1rem;
  }

  .agendamiento__containerc {
    position: relative;
  }

  .agenda {
    width: 100%;
    padding: 0;
    flex-direction: column;
  }

  .confirmacion__texto {
    font-size: 1.4rem;
  }

  .migaja__texto a {
    font-size: 1.2rem;
  }

  .presentacionTexto__etapa {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .agenda__formulario {
    width: 80%;
    padding: 6rem 1rem 1rem 1rem;
    font-size: 1.6rem;
  }

  .ecosistema__ul {
    flex-direction: column;
    /* margin-top: 2rem; */
    margin-left: 2rem;
  }

  .ecosistema__ul li {
    font-size: 1.4rem;
  }

  .etapa__proceso {
    padding: 0.8rem 1.1rem;
  }

  .ecosistema__form-campos {
    display: flex;
    flex-direction: column;
  }

  .presentacion__out-migaja {
    top: -5rem;
  }

  .inicia__subtitulo {
    width: 100%;
    padding: 2rem;
  }

  .inicia__subtitulo h3 {
    font-size: 1.8rem;
  }

  .inicia__subtitulo p {
    font-size: 1.4rem;
  }

  .inicia__subtitulo h4 {
    font-size: 1.8rem;
  }

  .inicia__subtitulo>div>img {
    width: 100%;
    height: 100%;
  }

  .ecosistemas__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    /* gap: 2rem; */
  }

  .eco__unirme {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 0 2rem;
  }

  .eco__textos-title {
    font-size: 3.2rem;
    width: 100%;
  }

  .eco__textos-subtitle {
    font-size: 4rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  .userin__container {
    padding: 2rem;
  }

  .userin__container>h4 {
    font-size: 2.6rem;
  }

  .eco__sombreado>h1 {
    font-size: 3.4rem;
  }

  .presentacion__migaja {
    top: 2rem;
    left: 2rem;
  }

  .ecoout__textos-titlegrande {
    margin-top: 2rem;
    max-width: 48rem;
    font-size: 3.2rem;
    font-weight: 700;
    text-align: left;
  }

  .eco__detalle-titulo {
    text-align: left;
    font-weight: 700;
    color: var(--texto-dark);
  }

  .eco__detalle-subtitulo {
    font-size: 1.4rem;
    text-align: left;
    font-weight: 400;
    margin-top: 1rem;
    line-height: 2;
  }

  .eco__detalle-titulo.centro {
    font-size: 2rem;
  }

  .eco__detalle-subtitulopequeno {
    font-size: 1.6rem;
    line-height: 1.5;
  }

  .inicia__subtitulo h2 {
    text-align: center;
  }

  .inicia__subtitulo-title {
    font-size: 3rem;
    text-align: center !important;
  }

  .inicia__subtitulo-detalle {
    font-size: 1.4rem;
  }

  .eco__sombreado h3 {
    font-size: 2.8rem;
    margin-bottom: 5rem;
  }

  .homeout__listDiv {
    margin-bottom: 3rem;
  }

  .homeout__listDiv>h2 {
    text-align: center !important;
  }

  .homeout__listDiv>div {
    flex-direction: column;
    padding-left: 3rem;
  }

  .eco__textos-titlegrande_d {
    font-size: 3.2rem;
    max-width: 50rem;
    text-align: left;
  }

  .ecocontacto__agrupador {
    box-shadow: none;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }

  .ecocontacto__agrupador input {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    padding: 1rem 2rem;
    border-radius: 1rem;
    outline: none;
    min-width: 100%;
  }

  .ecocontacto__agrupador button {
    padding: 1rem 2rem;
    border-radius: 1rem;
    border: none;
    outline: none;
    width: 100%;
  }

  .agendamiento__container-alerta-ok {
    width: 95%;
  }

  .inicia_inicia-sub {
    display: block;
    margin: auto;
    font-size: calc(2rem + 1vw);
    margin-bottom: 2rem;
    text-align: center !important;
  }

  .ocultar__mob {
    display: none !important;
  }

  .agenda__notificacion {
    padding-bottom: 5rem !important;
    line-height: 1.3 !important;
  }

  /* .confirmacion__texto {
    padding-top: 3rem !important;
    padding-bottom: 2rem !important;
  } */

  .empleabilidad-proceso {
    margin-top: -6rem;
  }

  .container-steps {
    flex-direction: column;
  }

  .eco__textos-descrip {
    width: 100%;
    font-size: 2rem;
  }

  .div__sin__agendas h2 {
    font-size: 2rem !important;
  }

  .div__sin__agendas p {
    text-align: left;
  }

  .container__buscador {
    text-align: end;
    width: 100%;
  }

  .body__eco__detalle {
    min-height: 0;
  }

  .body__eco__detalle p {
    margin-bottom: 2.5rem;
  }

  .div__cupones {
    margin: 0;
  }

  .cupones__cards .alice-carousel__stage-item {
    padding: 1rem;
  }

  .modal__cupon-codigo {
    width: auto;
  }

  .modal__cupon-trimestral-solicitado {
    padding: 5rem 2rem 10rem 2rem;
  }

  .cupones__cards .alice-carousel__stage {
    left: 0px;
  }

  .div__imagen-fractalia img {
    max-width: 100%;
  }

  .legal__consultar {
    text-align: start;
  }

  .descripcion-buro {
    width: 100% !important;
  }

  .proteccion-online__beneficios {
    font-size: 14px;
  }

  .proteccion__body {
    padding-top: 0;
  }
}

.checkAlertas {
  display: flex;
  width: 35%;
  margin: 2rem 15rem 0px 0rem;
  position: relative;
  z-index: 1;
  align-items: start;
  text-align: left;
}

.empleabilidad-proceso {
  width: 70%;
  margin: 1rem auto 5rem;
  text-align: center;
  padding: 10px;
}

.empleabilidad-proceso h1 {
  font-size: calc(2rem + 1vw);
  margin-bottom: 3rem !important;
}

.empleabilidad-proceso p {
  font-size: 18px !important;
  margin-bottom: 5rem;
}

.empleabilidad-proceso img {
  width: 50px;
}

.container-steps {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div__notificacion__citas__agendadas {
  background-color: rgba(236, 17, 26, 0.15);
  box-shadow: 2px 2px 2px 1px #4462f51c !important;
  border-radius: 5px;
}

.div__notificacion__citas__agendadas>p {
  padding: 1.5rem;
  font-size: 14px;
}

.div__tiene__cita {
  position: absolute;
  background-color: var(--rojo);
  color: #fff;
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  font-size: 1.8rem;
  z-index: 1;
  padding: 0.5rem 1rem;
  top: 22px;
  right: 0;
}

.div__modal__cita,
.div__modal__coursera,
.div__modal__cv {
  text-align: center;
  padding-left: 6.1rem;
  padding-right: 6.1rem;
  padding-top: 3rem;
  padding-bottom: 5rem;
}

.div__modal__cita,
.div__modal__coursera {
  padding-left: 4.1rem;
  padding-right: 4.1rem;
}

.div__modal__cita>h1,
.div__modal__coursera>h1,
.div__modal__cv>h1 {
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  font-size: 20px;
}

.div__modal__cita>p,
.div__modal__coursera>p,
.div__modal__cv>p {
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 15px;
}

.div__modal__coursera>p {
  font-size: 15px;
}

.div__info__cita {
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.div__info__cita>p {
  text-align: left;
  font-size: 1.8rem;
}

.agenda__btn__cita,
.btn__ver__beneficios,
.btn__modal__coursera,
.btn__modal__cv {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

.agenda__btn__cita button,
.btn__ver__beneficios button,
.div__agendamientos__botones button,
.container__buscador button,
.modal__cupon-trimestral-solicitado button,
.div__mas-cupones button,
.btn__modal__coursera a,
.btn__modal__cv button {
  width: 15rem;
  padding: 1.2rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid #fff;
  font-weight: 700;
  cursor: pointer;
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
}

.btn__modal__cv button {
  width: 20rem !important;
}


/*HOVER BOTONES NEGROS*/

/* .agenda__btn__cita button:hover,
.btn__ver__beneficios button:hover,
.div__agendamientos__botones button:hover,
.container__buscador button:hover,
.modal__cupon-trimestral-solicitado button:hover,
.div__mas-cupones button:hover,
.btn__modal__coursera a:hover {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #ffffff !important;
} */

.agenda__btn__cita>button:disabled,
.div__agendamientos__botones>button:disabled,
.div__mas-cupones>button:disabled .agenda__btn>button:disabled {
  cursor: no-drop;
  background-color: #a7a7a7 !important;
  border-color: #a7a7a7 !important;
  color: #ffffff !important;
}

.div__agendamientos__botones {
  display: inline-flex;
  gap: 1rem;
  margin: auto;
  padding-top: 3rem;
}

.div__sin__agendas {
  text-align: center;
  padding-top: 5rem;
}

.div__sin__agendas h2 {
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  font-size: 2.9rem;
  padding-bottom: 2rem;
}

.div__sin__agendas p {
  font-size: 17px;
  padding-bottom: 2rem;
}

.div__agendamientos {
  text-align: center;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.div__agendamientos h2 {
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  padding-bottom: 2rem;
}

.div__copiar__calendario {
  padding-top: 4rem;
  font-size: 18px;
}

.div__copiar__calendario p {
  padding-bottom: 1rem;
}

.icons__calendar {
  display: inline-flex;
  gap: 3.5rem;
}

.icons__calendar img {
  cursor: pointer;
}

.table__agendamientos {
  text-align: center;
}

.texto_informativo_cp {
  margin-top: 0.6rem;
  color: #0000009c;
}

@media screen and (min-width: 648px) {
  .lista__alertas {
    padding-left: 3.5rem;
  }

  .alertas__body,
  .salarios__body {
    padding-right: 12rem;
  }
}

@media screen and (max-width: 648px) {

  .div__modal__coursera,
  .div__modal__cv {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
  }
}

.images-curriculo img {
  height: 300px;
  cursor: pointer;
}

.images-curriculo-color img {
  height: 420px !important;
  cursor: pointer;
}

.images-curriculo-active {
  border: 1px black solid;
}

@media screen and (min-width: 648px) {
  .images-curriculo img {
    height: 400px;
  }
}

@media screen and (min-width: 992px) {
  .images-curriculo img {
    height: 260px;
  }
}

.curriculo_content__label {
  font-family: 'Scotia-Bold';
}

.curriculum-select {
  width: 100%;
  padding: 0.8rem 1.6rem;
  border-radius: .3rem;
  font-size: 1.2rem;
}

.curriculo_content__colors {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: 22rem;
}

.curriculo_content__colors div {
  flex-grow: 1;
  height: 3.4rem;
  cursor: pointer;
}

.curriculo_content__colors div:nth-child(1) {
  background: #000000;
}

.curriculo_content__colors div:nth-child(2) {
  background: #0089FF;
}

.curriculo_content__colors div:nth-child(3) {
  background: #35994E;
}

.curriculo_content__colors div:nth-child(4) {
  background: #5A2D82;
}

.curriculo_content__colors div:nth-child(5) {
  background: #FF8900;
}