.modulos {
  display: flex;
  gap: 2rem;
  padding-top: 1.7rem;
}

.modulos__eco {
  display: flex;
  align-items: center;
  padding: 2rem 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  font-family: 'Scotia-Headline';
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 8%);
  border-radius: 1rem;
  /* width: 12rem; */
  width: 180px;
  height: 85px;
  color: #393939;
  background-color: #fff;
  justify-content: center;
}

.modulos__eco>p {
  vertical-align: middle;
}

.modulos__eco img {
  mix-blend-mode: multiply;
}

.modulos__eco:hover {
  background-color: var(--fondo);
}

.modulos__eco.activo.verde {
  /* background-color: var(--verde-fondo); */
  background-color: #84D9C6;
  /* color: var(--verde); */
  color: black;
  border: 1.5px solid var(--verde);
}

.modulos__eco.activo.verde-gris {
  background-color: #D5FAF2;
  /* color: var(--verde-gris); */
  color: black;
  border: 1.5px solid var(--verde-gris);
}

.modulos__eco.activo.naranjo-rosado {
  background-color: #FDA8DE;
  /* color: var(--naranjo-rosado); */
  color: black;
  border: 1.5px solid var(--naranjo-rosado);
}

.modulos__eco.activo.naranja {
  /* background-color: var(--naranja-fondo); */
  background-color: #FFEFEA;
  /* color: var(--naranja); */
  color: black;
  border: 1.5px solid var(--naranja);
}

.modulos__eco.activo.naranja2 {
  /* background-color: var(--naranja-fondo); */
  background-color: #FBD8CC;
  /* color: var(--naranja); */
  color: black;
  border: 1.5px solid var(--naranja2);
}

.modulos__eco.activo.morado {
  background-color: var(--morado-fondo);
  /* color: var(--morado); */
  color: black;
  border: 1.5px solid var(--morado);
}

.modulos__eco.activo.azul {
  background-color: var(--azul-fondo);
  /* color: var(--azul); */
  color: black;
  border: 1.5px solid var(--azul);
}

.modulos__eco.activo.celeste {
  background-color: var(--celeste-fondo);
  /* color: var(--celeste); */
  color: black;
  border: 1.5px solid var(--celeste);
}

.modulos__eco.activo.naranja-claro {
  background-color: var(--naranja-claro-fondo);
  /* color: var(--naranja-claro); */
  color: black;
  border: 1.5px solid var(--naranja-claro);
}

.modulos__eco.activo.morado-claro {
  background-color: var(--morado-claro-fondo);
  color: var(--morado-claro);
  border: 1.5px solid var(--morado-claro);
}

.div__prev__button,
.div__next__button {
  background: none;
  border: none;
}

@media screen and (min-width: 1024px) {
  .container__modulos {
    max-width: 720px;
  }
}

@media screen and (min-width: 1200px) {
  .container__modulos {
    max-width: 800px !important;
  }
}

@media screen and (min-width: 1400px) {
  .container__modulos {
    max-width: 980px !important;
  }
}

@media screen and (min-width: 1600px) {
  .container__modulos {
    max-width: 1140px !important;
  }
}

@media screen and (min-width: 1800px) {
  .container__modulos {
    max-width: 1320px !important;
  }
}

@media screen and (max-width: 768px) {
  .modulos {
    display: flex;
    align-items: center;
    text-align: center;
    flex-wrap: no-wrap;
    overflow-x: scroll;
    overflow-y: visible;
    position: relative;
    padding: 2rem 0;
  }

  .modulos__eco {
    min-width: 15rem;
    padding: 1.5rem 0.5rem;
    font-size: 1.4rem;
    text-align: left;
  }

  .modulos__eco>p,
  img {
    height: auto;
    /* padding-left: 10px; */
  }
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  width: 0;
  padding: 0;
  display: none;
}

@media screen and (min-width: 769px) {
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    position: absolute;
    top: 37% !important;
    display: block !important;
  }

  .alice-carousel__next-btn {
    right: 0;
  }

  .alice-carousel__prev-btn {
    left: -30px;
  }
}