.miga__box_c {
  background-color: #EEEEEE;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  color: #333333;
}

.miga__texto {
  width: 80%;
  margin: 0 auto 0 5rem;
  display: flex;
}

.miga__link {
  color: #333333;
  margin: 0 1rem;
  font-size: 1.4rem;
  text-decoration: none;
}

.miga__detalle {
  display: flex;
  align-items: center;
}

.box__presentacion__in {
  display: flex;
  width: 90%;
  margin: 0px 0 0 10%;
}

.box__presentacion__extra {
  display: flex;
  width: 80%;
  margin: 50px auto;
  justify-content: space-between;
}

.box__presentacion__cards {
  width: 80%;
  margin: 50px auto;
  justify-content: space-between;
}

.box__presentacion__vida_bienestar {
  display: flex;
  width: 85%;
  margin: 0px auto;
  justify-content: space-between;
}

.box__presentacion__vida_bienestar > div > h1.vida {
  color: var(--verde-btn-vida);
  font-weight: 1000;
}

.iniciaProceso__btn {
  width: 24rem;
}

.box__section {
  margin-top: 30px;
  width: 100%;
  padding: 1rem 30px;
  background: rgba(145, 221, 248, 0.1);
}

.box_section_row {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.box_section_col {
  width: 20%;
  text-align: center;
  align-items: center;
}

.box__section_texto {
  color: #333333;
  padding: 10px 5px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.box__section_texto.act {
  color: #009dd6;
  border-bottom: 2px solid #009dd6;
}

.presentacion__in_div {
  width: 50%;
}

.presentacion__in_div img {
  object-fit: contain;
}

.presentacion__in_div.texto {
  width: 60%;
}

.presentacion__in_div.imagen {
  width: 40%;
}

.presentacion__in_div.imagen.vida {
  width: 50%;
}

.presentacion__in_div.imagen_score {
  margin: auto;
  width: 70%;
}

.presentacion__in_img {
  background-size: cover;
  width: 100%;
  height: auto;
}

.presentacion__in_descrip {
  width: 100%;
  height: auto;
  padding-right: 5rem;
  padding-top: 3rem;
}

.presentacion__in_descrip.minitexto {
  margin-left: 94px;
}

.presentacion__in_descrip > h2 {
  font-size: 30px;
}

.presentacion__in_descrip > div {
  font-size: 16px;
  margin-top: 15px;
  line-height: 1.5;
}

.presentacion__in_descrip.vida {
  padding-top: 1rem;
  padding-right: 12rem;
}

.presentacion__in_div > img {
  width: 100%;
}

.eco__cards {
  margin: 3rem auto;
  width: 80%;
  color: var(--texto);
}

.ecosistemas__cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
  gap: 0rem;
}

.eco__div_boxtwo {
  margin-top: 0px;
  font-size: 17px;
}

.eco__div_boxtwoTitle {
  width: 60%;
  margin-top: 60px !important;
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 3rem;
  font-family: "Scotia-Headline", 'Scotia', 'Open Sans', sans-serif;
}

.eco__div_boxtwoUl {
  width: 60%;
  margin-top: 30px;
  margin-top: 5px !important;
}

.eco__textos-titlegrande_c {
  margin-top: 2rem;
  max-width: 48rem;
  font-size: 5.5rem;
  font-weight: 700;
  padding-top: 8rem;
}

.addTop50 {
  margin-top: 50px;
}

.mostar_mobile {
  display: none;
}

.box_hv_servicios h2 {
  text-align: center;
  font-size: 3.5rem;
}

.presentacion__in_div .desktop_title {
  font-size: 30px;
}

.presentacion__in_descrip .desktop_desc {
  font-size: 20px;
}

@media screen and (max-width: 640px) {
  .miga__box {
    top: 2rem;
  }

  .miga__texto {
    margin: 0 auto 0 1.6rem;
  }

  .iniciaProceso__btn {
    width: 20rem;
    background-color: var(--rojo);
    padding: 1rem 1.4rem;
    border-radius: 3rem;
    border: 1px solid var(--rojo);
    margin-top: 2rem;
    color: #ffff;
    font-weight: 700;
    font-size: 1.4rem;
    cursor: pointer;
    text-align: center;
  }

  .eco__cards {
    margin: 6rem auto;
    width: 90%;
  }

  .ecosistemas__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    gap: 2rem;
  }

  .ecosistemas__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }

  .box__presentacion__extra {
    width: 100%;
    margin: 0rem;
  }

  .presentacion__in_descrip {
    width: 100%;
    height: auto;
    padding: 3rem;
    margin: 0;
    padding-left: 0rem !important;
  }

  .presentacion__in_div {
    width: 100%;
  }
  .presentacion__in_descrip.vida {
    padding-top: 1rem;
    padding-right: 0rem;
  }

  .box_hv_servicios h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: var(--texto-dark);
  }

  .eco__div_boxtwo {
    width: 100%;
    margin-top: 0px;
  }

  .eco__div_boxtwoTitle {
    width: 100%;
    font-size: 2rem !important;
    font-weight: 700;
    color: var(--texto-dark);
    text-align: center;
  }

  .eco__div_boxtwoUl {
    width: 100%;
  }

  .box_section_row {
    width: 1000px;
  }

  .box_section_col {
    width: 200px;
  }

  .box__section {
    /* display: none; */
    overflow-x: scroll;
  }

  .ocultar_mobile {
    display: none;
  }

  .mostar_mobile {
    display: block;
  }

  .eco__textos-titlegrande_c {
    font-size: 3.2rem;
    text-align: left;
    color: var(--texto-dark);
    padding-top: 0;
  }

  .miga__texto {
    width: 90%;
  }

  .presentacion__in_div.texto {
    width: 100%;
  }

  .presentacion__in_div.imagen {
    width: 100%;
    padding: 3rem;
  }

  .addTop50 {
    margin-top: 0px;
  }

  .presentacion__in_descrip > h2 {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    line-height: 2;
    font-size: 28px;
  }

  .presentacion__in_descrip > h2.centro {
    width: 100%;
  }

  .presentacion__in_descrip > div.centro {
    width: 100%;
    text-align: center;
  }

  .presentacion__in_descrip.minitexto {
    margin-left: 0px;
  }

  .presentacion__in_descrip .descripcion_vida {
    font-size: 12px;
  }

  .presentacion__in_div .mobile_title {
    font-size: 20px;
  }

  .presentacion__in_descrip .mobile_desc {
    font-size: 12px;
  }
}
