.homeout__ofertas {
    padding: 3rem 15rem;
    text-align: center;
    margin-top: 2rem;
}

.homeout__ofertas h2 {
    font-weight: 700;
    font-size: 3.6rem;
    text-align: center;
    font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
    margin-bottom: 1rem;
}

.homeout__ofertas>p {
    font-weight: 400;
    font-size: 1.8rem;
    text-align: center;
}

.ofertas__groups {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    justify-items: center;
    gap: 2rem;
    margin-top: 3rem;
}

.ofertas__group {
    width: 30rem;

}

.ofertas__group-title {
    height: 22rem;
}

.ofertas__group-title img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
}

.ofertas__body p {
    padding: 1rem 0;
    min-height: 4rem;
    font-size: 1.5rem;
    color: var(--texto);
}

@media screen and (max-width: 768px) {
    .homeout__ofertas {
        padding: 0rem 3rem;
        margin-top: -14rem;
        text-align: center;
    }

    .homeout__ofertas h2 {
        font-size: 2.4rem;
        color: var(--texto-dark);
    }

    .homeout__ofertas>p {
        font-size: 1.6rem;
        color: var(--texto);
    }

    .ofertas__group-title {
        margin: 0 auto;

    }
}