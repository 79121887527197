.out__principalpresentacion {
  width: 100%;
  height: 67rem;
  background-image: url("../../../../assets/img/img_principal.webp");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  position: relative;
}

.out__principalpresentacion-abajo {
  position: absolute;
  bottom: 8rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 1.2rem;
  cursor: pointer;
}
.out__principalpresentacion-abajo div a {
  color: var(--texto-semidark);
}

.out__principalpresentacion-texto {
  padding: 8rem 15rem 0;
}
.out__principalpresentacion-texto div h2 {
  font-size: 4.8rem;
  font-weight: 400;
  color: black;
}
.out__principalpresentacion-texto div h2 span {
  font-weight: 700;
  font-family: "Scotia-bold", "Scotia", "Open Sans", sans-serif;
  letter-spacing: -0.01em;
}
.out__principalpresentacion-texto div h5 {
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 1rem;
  color: var(--texto);
}

/* ************************************* */
/* FORMULARIO HOME OUT PRINCIPAL */
/* ************************************* */
.out__principalpresentacion-activar {
  display: flex;
  flex-direction: column;
  width: 50rem;
  margin-top: 2rem;
  padding: 1rem 2rem 1rem 0;
}
.out__principalpresentacion-activar input {
  flex: 1;
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
  border: 1px solid var(--Color-Grises-desactivado, #D9D9D9);
  border-radius: 5px;
  font-family: "Scotia", "Open Sans", sans-serif;
  background-color: #fff;
}

.out__principalpresentacion-activar input::placeholder {
  color: #858b8c;
}
.out__principalpresentacion-activar input:focus {
  outline: none;
}
.out__principalpresentacion-activar button {
  width: 26.2rem;
  height: 5.5rem;
  background-color: var(--rojo);
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  padding: 1.5rem 2rem 1.5rem;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  margin-top: 2rem;
}
.out__principalpresentacion-activar button:hover,
.activacuenta__btn:hover {
  background-color: #000000;
}

.activacuenta__btn:hover {
  border-color: #000000;
}

.unirme__btn:hover,
.header__botones:hover,
.header__botonesconfondo:hover,
.btn__login:hover {
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #fff !important;
}

.out__principalpresentacion-error {
  color: var(--rojo);
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
}

@media screen and (max-width: 1115px) {
  .out__principalpresentacion-texto {
    padding: 8rem 6rem 0 10rem;
    margin-right: 33rem;
  }
}
@media screen and (max-width: 968px) {
  .out__principalpresentacion-texto {
    padding: 8rem 9rem 0 3rem;
    margin-right: 33rem;
  }
}

@media screen and (max-width: 768px) {
  .out__principalpresentacion {
    width: 100%;
    height: 56rem;
    background-image: inherit;
  }
  .out__principalpresentacion-texto {
    padding: 4rem 3rem;
    margin-right: 0rem;
  }

  .out__principalpresentacion-texto div h2 {
    font-size: 2.8rem;
    text-align: left;
  }
  .out__principalpresentacion-texto div h5 {
    font-size: 1.6rem;
    text-align: left;
    margin-top: 3rem;
  }

  .out__principalpresentacion-activar {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: inherit;
    margin-top: 3rem;
    padding: 0;
  }
  .out__principalpresentacion-activar input {
    flex: 1;
    padding: 1.5rem 1rem;
    border-radius: 0.5rem;
  }
  .out__principalpresentacion-activar button {
    width: 100%;
    margin-top: 2rem;
    font-size: 1.8rem;
  }

  .out__principalpresentacion-error {
    text-align: center;
    margin-top: 1.5rem;
    font-size: 1.6rem;
  }
}
