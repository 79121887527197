/* ***************************************** */
/* HOME IN USER */
/* ***************************************** */

.user__detalle-invitado,
.user__detalle-cerrar {
  display: flex;
  padding-left: 3rem;
  align-items: center;
  cursor: pointer;
}
.user__detalle-invitado img,
.user__detalle-cerrar img {
  height: 1.8rem;
  margin-right: 1rem;
}
.user__detalle-invitado span {
  text-decoration: underline;
  font-size: 1.4rem;
}
.user__detalle-section,
.user__detalle-invitado {
  border-bottom: 1px solid var(--texto-claro);
  padding-top: 1rem;
  padding-bottom: 3rem;
}
.user__detalle-invitado,
.user__detalle-cerrar {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: 'Scotia-Bold';
}
.user__detalle-section:hover,
.user__detalle-invitado:hover,
.user__detalle-cerrar:hover,
.user__detalle-agendamientos:hover {
  background-color: #f1f1f1;
}
.user__detalle-section p {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 1.6rem;
  font-family: 'Scotia-Bold';
}
.user__detalle-section div {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 4rem;
  border: 1px solid var(--rojo);
  margin-right: 2rem;
  margin-left: 2rem;
  font-size: 1.6rem;
  font-family: 'Scotia-Bold';
  cursor: pointer;
}
.user__detalle-cerrar span {
  font-size: 1.6rem;
  color: var(--rojo);
}
.user__detalle-agendamientos {
  border-bottom: 1px solid var(--texto-claro);
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
}

.user__detalle-agendamientos span {
  padding-left: 6rem;
  font-family: 'Scotia-Bold'
}

/* ***************************************** */
/* HOME OUT PRESENTACION */
/* ***************************************** */

.out__presentacion {
  display: flex;
  justify-content: center;
}
.presentacion__img,
.presentacion__texto {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
/* .presentacion__texto div div p strong {
  font-size: 2rem;
} */
.presentacion__texto-principal {
  max-width: 40rem;
}

.presentacion__img.media {
  display: none;
}
.presentacion__img {
  background-color: var(--fondo-principal-img);
  flex: 7;
}
.presentacion__img div {
  display: flex;
  justify-content: center;
  height: 53rem;
}
.presentacion__img img {
  height: 100%;
  width: 100%;
}
.presentacion__texto {
  background-color: var(--fondo-principal-text);
  flex: 6;
  color: #fff;
  text-align: right;
}
.presentacion__texto div h2 {
  font-size: 4.2rem;
  letter-spacing: -2px;
  font-weight: bold;
}
.presentacion__texto div p:nth-child(1) {
  margin-top: 2rem;
}
.presentacion__texto div p {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.presentacion__btn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 5rem;
}

/* ***************************************** */
/* HOME OUT PREGUNTAS */
/* ***************************************** */

/* ***************************************** */
/* HOME OUT PREGUNTAS */
/* ***************************************** */



/* ***************************************** */
/* HOME OUT NOSOTROS */
/* ***************************************** */
.nosotros {
  width: 60%;
  margin: 0 auto;
  letter-spacing: -1px;
}
.somos__btn {
  text-align: center;
}
.nosotros h2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 3rem 0;
}
.nosotros p {
  font-size: 1.5rem;
  line-height: 2;
  margin-bottom: 2rem;
  color: var(--texto);
}
.nosotros ul {
  margin-bottom: 6rem;
}

.hamburger {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  padding-left: 4rem;
}
.hamburger__title {
  color: var(--texto);
  font-size: 3rem;
  margin-top: 2rem;
}

.usermobile__group {
  margin-bottom: 2rem;
}
.usermobile__group i {
  margin-right: 1rem;
}

.presentacion__img.media {
  display: flex;
}
.presentacion__img.media div img {
  width: 15rem;
  height: 20rem;
}

.presentacion__img {
  display: none;
}

.presentacion__texto {
  text-align: center;
}

.presentacion__img {
  background-color: transparent;
}

.presentacion__btn {
  align-items: center;
}

.presentacion__img div {
  max-height: 30rem;
}



@media screen and (max-width: 640px) {
  .presentacion__texto {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .presentacion__img div {
    max-height: 20rem;
  }

  .presentacion__btn {
    width: 60%;
    margin: 2rem auto;
    margin-top: 0;
  }

  .hamburger__title {
    font-size: 2rem;
  }

  .user__detalle-agendamientos,
  .user__detalle-cerrar {
    text-align: center;
  }

  .user__detalle-cerrar {
    display: block;
    padding-left: 0rem;
  }

  .user__detalle-agendamientos span {
    padding-left: 0rem;
  }



  /* Zona icono usuario, perfil, cierre sesion */

  /* FALTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA */

  /* .header__user-detalle.mostrar {
    top: 1rem;
    left: calc(50% - 15rem);
    width: 80%;
    border: none;
    margin: 0 auto;
    padding: 0rem;
  } */
}
