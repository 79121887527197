.box_hv_servicios {
  width: 80%;
  margin: 30px auto;
  padding: 0 6rem;
}

.box_hv_servicios > h2 {
  width: 100%;
  text-align: center;
  font-size: 30px;
}

.box_hv_servicios > h3 {
  width: 100%;
  text-align: left;
  font-size: 20px;
}

.box_hv_servicios > div {
  width: 90%;
  text-align: center;
  margin: 50px auto;
  font-size: 16px;
  color: #333333;
}

.swal2-title{
  color: var(--morado);
  font-family: 'Scotia-bold';
  font-size: 2rem;
}

.customConfirm{
  padding: 1rem 6rem;
  background-color: #7849B8;
  font-size: 1.6rem;
}

.customContainer{
  height: 300px !important;
}

.customHtmlContainer{
  justify-self: center;
  max-width: 60%;
  font-size: 1.5rem !important;
  overflow: unset !important;
}

.swal2-popup{
  width: 40rem;
  height: 25rem;
}

.eco__cards {
  margin: 3rem auto;
  width: 80%;
}

.eco__cards__podcast {
  margin: 3rem auto;
  width: 100% !important;
}

.ecosistemas__cards {
  row-gap: 8rem;
  justify-items: center;
}

.ecosistemas__cards_podcast {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  gap: 3rem;
}

.ecosistemas__cards_forcecentered {
  display: flex;
  justify-content: center;
}
.ecosistemas__cards_forcecentered .eco__card {
  max-width: 430px;
  flex-shrink: 0;
}
.eco__card {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 22px;
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 8%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 490px;
}

.eco__card__beneficios {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 8%);
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.eco__card_title {
  width: 70%;
  margin: 20px auto 10px;
  font-size: 25px;
  font-weight: bolder;
  font-family: "Scotia-Headline", 'Scotia', 'Open Sans', sans-serif;
}
.eco__card_descrip {
  width: 80%;
  margin: 20px auto 50px;
  font-size: 16px;
  color: #333333;
  border-top: 1px gray solid;
  padding-top: 15px;
}
.eco__message {
  line-height: 28px !important;
}
.eco__card_btn {
  width: 75%;
  height: 48px;
  background-color: var(--rojo) !important;
  color: white;
  padding: 10px 20px;
  text-align: center;
  margin: 0 auto 20px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--rojo);
}
.eco__card_btn-white {
  width: 75%;
  height: 48px;
  background-color: transparent !important;
  color: var(--rojo);
  padding: 10px 20px;
  text-align: center;
  margin: 0 auto 20px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--rojo);
  font-family: "Scotia-Headline", 'Scotia', 'Open Sans', sans-serif;
}
.eco__card-img_beneficios {
  height: 17.6rem;
  width: 100% !important;
  position: relative;
  padding: 50px;
}
.eco_card_descrip_podcast {
  padding: 0em 2em 1em;
  margin-top: -4rem;
}

.eco_card_descrip_podcast > h2 {
  padding: 5px 0;
  font-size: 20px;
  font-weight: bolder;
  text-align: left;
}

.eco_card_descrip_podcast > div {
  padding: 5px 0;
  font-size: 14px;
  text-align: left;
  color: #333333;
}

.eco_pilares_fitness {
  text-align: left !important;
  color: #58595b !important;
}
.eco_pilares_fitness-extra {
  line-height: 35px;
}

.eco_pilares_fitness > p {
  margin-bottom: 10px;
}

.box_photo_select {
  border: 3px #657786 dashed;
  width: 150px;
  height: 150px;
  margin: 30px auto;
  display: flex;
}

.box_img_upload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF4D;
  border-radius: 4px;
}

.box_img_upload > p {
  padding-left: 1rem;
  margin: 0;
}

.box_img_perfil {
  position: relative;
  width: 30%;
}

.box_text_perfil {
  width: 70%;
  padding: 20px;
}

.box_img_perfil_mob {
  display: none;
}

.rowcss {
  display: flex;
}

.mostar__mob {
  display: none;
}

.redesImg {
  width: 20%;
}

.redesInput {
  width: 80%;
}

.flechas {
  display: flex;
  width: 50%;
  justify-content: center;
}

.flechaCanbio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 15%;
  margin: 1rem 0;
}

.tiposizq {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #979797;
}

.tiposider {
  width: 60%;
  border-right: 1px solid rgb(151, 151, 151);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f9fafc;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

#frameColorPlantilla{
  width: 90%;
  height: 100%;
}

.separatorNew {
  width: 100%;
  display: flex;
}

.separator1New {
  width: 75%;
}

.separator2New {
  width: 25%;
  text-align: center;
  align-items: center;
}

.separator1New > p {
  margin-bottom: 0px;
}

.marginTop0 {
  margin-top: 0px;
}

.fondoProgressbar {
  height: 15px;
  background-color: #e1e8ed;
  width: 100%;
  border-radius: 41px;
  margin: 15px auto;
}

.fondoProgressbarCarga {
  height: 15px;
  background-color: var(--morado);
  width: 25%;
  border-radius: 41px;
}
.css-1s2u09g-control {
  font-size: 14px;
}

.widthFinalizar {
  width: 50%;
}

.widthFinalizarbtn {
  width: 315px;
  margin: auto;
}

@media screen and (min-width: 1600px) {
  .flechas{
    opacity: 0;
  }
}

@media screen and (max-width: 640px) {

  .customContainer{
    height: 340px !important;
  }
  
  .customHtmlContainer{
    max-width: 100%;
  }
  

  #frameColorPlantilla{
    width: 100%;
    height: 100%;
  }

  .iframe_div{
    width: 280px !important;
  }

  .eco__card{
    height: 510px;
  }

  .box_hv_servicios > div {
    width: 100%;
    margin: 20px auto;
  }

  .box_hv_servicios > h2 {
    font-size: 20px;
  }

  .box_img_upload {
    top: 9%;
    left: 50%;
  }

  .flechas {
    justify-content: space-around;
  }

  .eco__cards {
    margin: 6rem auto;
    width: 100% !important;
    /* color: var(--texto); */
  }

  .ecosistemas__cards,
  .ecosistemas__cards_forcecentered {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    gap: 2rem;
  }

  .ecosistemas__cards,
  .ecosistemas__cards_forcecentered {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    /* gap: 2rem; */
    justify-items: center;
  }

  .ecosistemas__cards_podcast {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    /* gap: 1rem; */
  }

  .box_hv_servicios {
    width: 100%;
    padding: 0 3rem;
  }

  .box_img_perfil {
    display: none;
  }

  .box_text_perfil {
    width: 100%;
  }

  .separator {
    display: inline !important;
  }

  .widthFinalizar {
    width: 100%;
  }

  .widthFinalizarbtn {
    width: 100%;
  }

  .box_img_perfil_mob {
    display: block;
    width: 100%;
  }

  .box_photo_select {
    margin: 10px auto 10px;
    width: 180px;
    height: 180px;
    background-position: center !important;
  }
  .form__filtrosPerso {
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }
  .ocultar__mob {
    display: none;
  }

  .mostar__mob {
    display: block;
  }

  .redesImg {
    width: auto;
  }

  .redesInput {
    width: 100%;
  }

  .btnGuardar,
  .btnAdd {
    margin: 30px auto 20px;
    width: 100% !important;
  }

  .separatorNew {
    display: inline;
  }

  .flexPLantilla {
    display: flex;
  }

  .tiposizq,
  .tiposider {
    width: 100%;
    border-right: none;
  }

  .tiposider {
    height: 400px;
    border: solid 1px #a7a7a7;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  .tiposizq {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
    gap: 1.5rem;
    color: var(--texto);
    margin-top: 3rem;
  }

  .box_hv_servicios h3 {
    text-align: center !important;
    font-size: 14px;
  }

  .box_hv_servicios p {
    margin-top: -8rem;
    text-align: justify;
  }
  .eco_pilares-text {
    text-align: center !important;
    margin-top: 3rem !important;
  }
  .eco__empleabilidad-message {
    text-align: center !important;
  }

  .borderBoxCv {
    min-width: 0 !important;
    padding: 0rem 0rem 2rem 0 !important;
  }
}

.borderBoxCv {
  padding: 2rem 3rem;
  min-width: 440px;
}

.raya {
  width: 100%;
  height: 2px;
  background-color: #657786;
}

.eco_titulo_h3 {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Open Sans', 'Scotia', sans-serif;
  letter-spacing: 0px;
  color: #5c7c71;
  opacity: 1;
  padding-top: 10px;
}

.eva-medica {
  padding-top: 65px !important;
}
