
.out__activarcuenta{
    padding-top: 4rem;
    margin-top: 5rem;
    padding-bottom: 6rem;
    text-align: center;
}
.out__activarcuenta h3{
    font-size: 3.6rem;
    font-weight: 700;
    color: var(--texto-dark);
    font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
}
.out__activarcuenta p{
    font-size: 1.6rem;
    color: var(--texto);
    font-weight: 700;
    font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
}

@media screen and (max-width: 768px) {
    .out__activarcuenta{
        margin-top: 6rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .out__activarcuenta h3{
        font-size: 2.4rem;
        margin-bottom: 2rem;
    }
    .out__activarcuenta p{
        text-align: start;
        padding: 0 2rem;
    }
}