.out__preguntas {
  margin: 0 auto 10rem auto;
  display: flex;
  padding: 0rem 15rem;
}

.preguntas__titulo {
  flex: 1;
  padding-top: 1.5rem;
}

.preguntas__separador {
  margin-top: 6rem;
  width: 5rem;
  height: 0.5rem;
  background-color: var(--rojo);
}

.preguntas__titulo h3 {
  color: var(--texto-dark);
  font-size: 3.2rem;
  padding: 3rem 0 3rem 0;
  font-weight: 700;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
}

.pregunta__titulo h4 {
  color: rgba(30, 30, 30, 1);
}

.preguntas__titulo p {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--texto);
  max-width: 45rem;
}

.preguntas__titulo div {
  border: 1px solid var(--rojo);
  max-width: 30rem;
  padding: 1.5rem 3rem;
  border-radius: 0.5rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--rojo);
  margin-top: 4rem;
  text-align: center;
}


/* ***************************************** */
/* MEDIA SCREEN */
/* ***************************************** */

@media screen and (max-width: 768px) {
  .out__preguntas {
    padding: 2rem 3rem;
    flex-direction: column;
    text-align: start;
  }

  .preguntas__titulo h3 {
    font-size: 2.4rem;
  }

  .preguntas__titulo p {
    font-size: 1.6rem;
  }

  .pregunta__titulo h4 {
    font-size: 1.6rem;
  }

  .pregunta__detalle p {
    font-size: 1.4rem;
    text-align: left;
  }
}

@media screen and (max-width: 640px) {
  .pregunta__detalle {
    font-size: 1.6rem;
  }

  .preguntas__titulo div {
    max-width: 100%;
  }
}