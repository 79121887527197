.encuesta__coursera {
  text-align: center;
  font-size: 1.6rem;
}

.encuesta__coursera > h1 {
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  font-weight: 700;
}

.encuesta__coursera > h3 {
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 31px;
}

.encuesta__coursera > h3.salud-confirma {
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
  font-weight: 500 !important;
  font-size: 1.45rem !important;
  color: var(--celeste) !important;
}



.preguntas__modal {
  margin: 0 8em;
}

.preguntas__modal .titulo_seccion {
  font-family: "Scotia", "Open Sans", sans-serif;
  padding-top: 50px;
  font-weight: lighter;
}

.card__btns__encuesta {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.custom-checkbox input {
  width: 10% !important;
  margin-right: 0rem;
}

.custom-checkbox label {
  width: 85% !important;
  margin-right: 0px !important;
  margin-bottom: 10px;
}

@media screen and (max-width: 640px) {
  .preguntas__modal {
    margin-left: 1.8rem;
    margin-right: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .activacuenta__btn,
  .unirme__btn {
    padding: 1.5rem 0rem !important;
  }

  .card__btns__encuesta {
    flex-direction: column;
    gap: 1rem;
  }
}




/* encuesta salud  */
