@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

@font-face {
  font-family: 'Scotia';
  src: url('../assets/fonts/Scotia_Bd.ttf') format('truetype');
  src: url('../assets/fonts/Scotia_Headline.ttf') format('truetype');
  src: url('../assets/fonts/Scotia_Lt.ttf') format('truetype');
  src: url('../assets/fonts/Scotia_Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'Scotia-Headline';
  src: url('../assets/fonts/Scotia_Headline.ttf') format('truetype');
}

@font-face {
  font-family: 'Scotia-Bold';
  src: url('../assets/fonts/Scotia_Bd.ttf');
}

@font-face {
  font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-Regular.ttf') format('truetype');
}

:root {

  --fuente-bold: 'Scotia-Bold';
  --fuente-regular: 'Scotia-Headline';

  /* Colores Oficiales */
  --rojo: #EC111A;
  --amarillo: #F6D14E;
  --gris: #E9E9E9;
  --texto: #000000;
  --texto-dark: #1E1E1E;
  --texto-input: #858B8C;
  --texto-semidark: #4F5053;
  --texto-contacto: #3C3F40;
  --texto-validador: #363C49;
  --borde: #D6DCE8;
  --borde-agenda: #F0F0F0;
  --borde-claro: #EFF0F1;
  --borde-form: #979797;
  --bg_verde: #EDF6F3;
  --verde: #138468;
  --bg_cards: #f0faf8;
  --bg_grey: #f7f4fb;
  --bg_claro: #FAFAFE;
  --azul: #0070b8;
  --border-agenda: #D6DCE8;
  --plomo: #797D86;
  --azul-oscuro-404: #302F77;
  --celeste-success: #91DDF8;

  --texto-encuesta: #58595B;
  --texto-claro: #e1e1e1;
  --rosado: #fbd4d5;
  /* --plomo: #8e8e93; */
  --fondo-plomo: #f6f8fa;
  --fondo-principal-img: #ff969c;
  --fondo-principal-text: #ec1c24;
  /* --verde: #00a693; */
  --verde-claro: #9EE4DC;
  --verde-fondo: #CBECE8;
  --naranja: #fb6330;
  --naranja2: #E96D41;
  --naranja-claro2: #FEBA89;
  --naranja-fondo: #FDDFD5;
  --morado: #7849b8;
  --morado-claro2: #AFA8F6;
  --morado-fondo: #E6D4FE;
  --azul-claro: #0070b8;
  --azul-fondo: #C6F0FF;
  --celeste: #009dd6;
  --celeste-fondo: #C6F0FF;
  /* --naranja-claro: #C97B72; */
  --naranja-claro: #FF9E80;
  --naranja-claro-fondo: #FACEC1;
  --morado-claro: #7986CB;
  --morado-claro-fondo: #E4E7F5;
  --fondo: #F6F8FA;
  --confirmacion: #7DC855;
  --confirmacion-fondo: #F5FFF0;
  --fondo-transparente-opaco: rgba(0, 0, 0, 0.6);
  --input: #E8F0FE;
  --borde: #bababa;
  --verde-oscuro: #9E9E9E;
  --verde-borde: #66BD9C;
  --verde-check: #92D171;
  --verde-gris: #5C7C71;
  --naranjo-rosado: #F2609E;
  --cafe-gris: #A69C94;

  /* vida */
  --verde-btn-vida: #00A993;

  /* Detalle Ecosistema */
  --coursera: #009DD6;
  --coursera-secundario: #91DDF8
    /*  */
    --verde-opaco: #00a693;
  --naranja-opaco: #fb6330;
  --morado-opaco: #7849b8;
  --azul-opaco: #0070b8;
  --celeste-opaco: #009dd6;
  --naranja-opaco-claro: #ff9e80;
  --morado-opaco-claro: #7986CB;

  --selected-color: #7dc855;
  --unselected-color: #dfdfdf;
  --unselected-text-color: #b2b5ba;

  --fondo-verde-score: #D0EAC7;
  --texto-verde-score: #119600;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  font-family: "Scotia", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased !important;
}

body {
  font-family: "Scotia", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased !important;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Asegura que ocupe toda la ventana */
}

.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Para que crezca ocupando espacio */
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 0rem;
}

a {
  color: #000;
  text-decoration: none;
}

button {
  font-size: 16px;
}

.mobile {
  display: inherit !important;
}

.mobile-flex {
  display: flex !important;
}

.desktop {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}


/* ******************************** */
/* BOTONES */
/* ******************************** */
.creacuenta__btn,
.activacuenta__btn,
.unirme__btn,
.vermas__btn,
.comenzar__btn,
.homeout__btn,
.iniciaProceso__btn {
  width: 25rem;
  background-color: var(--rojo);
  padding: 1.2rem 2rem;
  border-radius: 8px;
  border: 1px solid var(--rojo);
  margin-top: 2rem;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
  text-align: center;
  font-family: 'Scotia-Bold', 'Scotia', 'Open Sans', sans-serif;
  text-decoration: none;
}

.eco__card-btn .vermas__btn {
  border-radius: 50px;
  font-family: 'Scotia';
  font-size: 1.5rem;
}

.iniciaProceso__btn:hover{
  background-color: #000000 !important;
  border-color: #000000 !important;
  color: #fff !important;
}





.iniciaProceso__btn.verde {
  background-color: var(--verde-btn-vida);
  border: none;
}

.vidaplus__btn {
  width: 30rem;
  background-color: var(--verde-gris);
  padding: 1.2rem 2rem;
  border-radius: 8px;
  border: 1px solid var(--verde-gris);
  margin-top: 2rem;
  color: #fff;
  font-weight: bold !important;
  font-size: 1.8rem;
  cursor: pointer;
  text-align: center;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
  text-decoration: none;
}

.pfelemental__btn {
  width: 30rem;
  background-color: var(--naranjo-rosado);
  padding: 1.2rem 6rem;
  border-radius: 8px;
  border: 1px solid var(--naranjo-rosado);
  margin-top: 2rem;
  color: #fff;
  font-weight: bold !important;
  font-size: 1.6rem;
  cursor: pointer;
  text-align: center;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
  text-decoration: none;
}

.taxi__btn {
  width: 72rem;
  background-color: var(--cafe-gris);
  padding: 2.3rem 2rem;
  border-radius: 8px;
  border: 1px solid var(--cafe-gris);
  margin-top: 2rem;
  color: #fff;
  font-weight: bold !important;
  font-size: 2.3rem;
  cursor: pointer;
  text-align: center;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
  text-decoration: none;
}

.verde__btn {
  width: 30rem;
  background-color: var(--verde);
  padding: 1.2rem 2rem;
  border-radius: 8px;
  border: 1px solid var(--verde);
  margin-top: 2rem;
  color: #fff;
  font-weight: bold !important;
  font-size: 1.8rem;
  cursor: pointer;
  text-align: center;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
  text-decoration: none;
}

.score_credito_riesgo {
  width: 14rem;
  background-color: var(--fondo-verde-score);
  padding: 0.4rem 2rem;
  border-radius: 5px;
  border: 1px solid var(--fondo-verde-score);
  color: var(--texto-verde-score);
  font-weight: bold !important;
  font-size: 1.8rem;
  text-align: center;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
  margin-left: 2rem;
}

.vermas__btn {
  font-family: 'Scotia-Bold', "Scotia", "Open Sans", sans-serif;
  text-decoration: none;
}

.homeout__btn {
  width: 23rem;
}

.iniciaProceso__btn {
  width: 26rem;
}

.subtitulo__group-ul {
  display: flex;
  gap: 2rem;
}

.homeout__list {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.homeout__list li {
  /* list-style-type: circle !important; */
  margin-left: 1.5rem;
  line-height: 2;
  font-size: 1.6rem;
}

.cuestionario__radio-button {
  width: 2em;
  height: 2em;
  accent-color: var(--naranjo-rosado);
  vertical-align: text-bottom;
  margin-right: 15px;
}

.cuestionario__label {
  vertical-align: text-bottom;
}

.cuestionario__checkbox {
  width: 1.6em;
  height: 1.6em;
  accent-color: var(--naranjo-rosado);
  vertical-align: text-bottom;
  filter: none !important;
}

.autorizacion__checkbox {
  width: 2.2em;
  height: 2.2em;
  accent-color: var(--naranjo-rosado);
  filter: none !important;
}

.css-tlfecz-indicatorContainer {
  color: var(--naranjo-rosado) !important;
}

input {
  font-family: 'Scotia', 'Open Sans', sans-serif;
  background-color: #FFFFFF;
  font-size: 15px;
}

.custom_checkbox input {
  transform: scale(1.6);
  margin-left: 0.3em;
  margin-right: 0.8em;
}

.vermas__btn {
  width: 15rem;
  font-size: 1.8rem;
  letter-spacing: 0;
}

.vermas__btn > div {
  pointer-events: none;
}

.comenzar__btn {
  width: 15rem;
  font-size: 1.8rem;
  letter-spacing: -1px;
  margin: auto;
}

.unirme__btn {
  background-color: #fff;
  color: var(--rojo);
  border: 1px solid var(--rojo);
}

.unirme__btn:hover,
.vermas__btn,
.comenzar__btn {
  background-color: #fff;
  color: var(--fondo-principal-text);
}

.activacuenta__btn.rojo,
.unirme__btn:hover.rojo,
.vermas__btn.rojo,
.comenzar__btn.rojo {
  color: var(--rojo);
  border: 1px solid var(--rojo);
}

.activacuenta__btn.rojo.fondo,
.unirme__btn:hover.rojo.fondo {
  color: #fff;
  background-color: var(--rojo);
}

.activacuenta__btn.verde,
.unirme__btn:hover.verde,
.vermas__btn.verde,
.comenzar__btn.verde {
  background-color: var(--verde);
  color: #fff;
  border: none;
}

.activacuenta__btn.verde-gris,
.unirme__btn:hover.verde-gris,
.vermas__btn.verde-gris,
.comenzar__btn.verde-gris {
  background-color: var(--verde-gris);
  color: #fff;
  border: none;
}

.activacuenta__btn.naranjo-rosado,
.unirme__btn:hover.naranjo-rosado,
.vermas__btn.naranjo-rosado,
.comenzar__btn.naranjo-rosado {
  background-color: var(--naranjo-rosado);
  color: #fff;
  border: none;
  width: 20rem;
}

.activacuenta__btn.verde.fondo,
.unirme__btn:hover.verde.fondo {
  color: #fff;
  background-color: var(--verde);
}

.activacuenta__btn.naranja,
.unirme__btn:hover.naranja,
.vermas__btn.naranja,
.comenzar__btn.naranja {
  background-color: var(--naranja);
  color: #fff;

}

.activacuenta__btn.naranja.fondo,
.unirme__btn:hover.naranja.fondo {
  color: #fff;
  background-color: var(--naranja);
}

.activacuenta__btn.morado,
.unirme__btn:hover.morado,
.vermas__btn.morado,
.comenzar__btn.morado {
  background-color: var(--morado);
  color: #fff;
}

.activacuenta__btn.naranja2,
.unirme__btn:hover.naranja2,
.vermas__btn.naranja2,
.comenzar__btn.naranja2 {
  background-color: var(--naranja2);
  color: #fff;
}

.activacuenta__btn.morado.fondo,
.unirme__btn:hover.morado.fondo {
  color: #fff;
  background-color: var(--morado);
}

.activacuenta__btn.azul,
.unirme__btn:hover.azul,
.vermas__btn.azul,
.comenzar__btn.azul {
  background-color: var(--azul);
  color: #fff;
}

.activacuenta__btn.azul.fondo,
.unirme__btn:hover.azul.fondo {
  color: #fff;
  background-color: var(--azul);
}

.activacuenta__btn.celeste,
.unirme__btn:hover.celeste,
.vermas__btn.celeste,
.comenzar__btn.celeste {
  background-color: var(--celeste);
  color: #fff;
}

.activacuenta__btn.celeste.fondo,
.unirme__btn:hover.celeste.fondo {
  color: #fff;
  background-color: var(--celeste);
}

.activacuenta__btn.naranja-claro,
.unirme__btn:hover.naranja-claro,
.vermas__btn.naranja-claro,
.comenzar__btn.naranja-claro {
  background-color: var(--naranja-claro);
  color: #fff;
}

.activacuenta__btn.naranja-claro.fondo,
.unirme__btn:hover.naranja-claro.fondo {
  color: #fff;
  background-color: var(--naranja-claro);
}

.activacuenta__btn.morado-claro,
.unirme__btn:hover.morado-claro,
.vermas__btn.morado-claro,
.comenzar__btn.morado-claro {
  background-color: var(--morado-claro);
  color: #fff;
}

.activacuenta__btn.morado-claro.fondo,
.unirme__btn:hover.morado-claro.fondo {
  color: #fff;
  background-color: var(--morado-claro);
}

.activacuenta__btn.verde-vida {
  color: #fff;
  background-color: var(--verde-btn-vida);
  border: none;
}


.unirme__btn.verde-vida {
  color: var(--verde-btn-vida);
  border-color: var(--verde-btn-vida);
}

.activacuenta__btn.azul-salud {
  color: #fff;
  background-color: var(--celeste);
  border: none;
  font-size: 15px ;
}

.unirme__btn.azul-salud {
  color: var(--celeste);
  border-color: var(--celeste);
  font-size: 15px ;
 
}

.unirme__btn.azul-salud-volver {
  color: white;
  background-color: #626262;
  border-color: #626262;
}

.vermas__btn:hover {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.eco__card-btn .vermas__btn.morado {
  background-color: #fff;
  color: var(--morado);
  border: 1px solid var(--morado);
}

.eco__card-btn .vermas__btn.verde {
  background-color: #fff;
  color: var(--verde);
  border: 1px solid var(--verde);
}

.eco__card-btn .vermas__btn.celeste {
  background-color: #fff;
  color: var(--celeste);
  border: 1px solid var(--celeste);
}

.eco__card-btn .vermas__btn.naranja2 {
  background-color: #fff;
  color: var(--naranja2);
  border: 1px solid var(--naranja2);
}
.eco__card-btn .vermas__btn.verde-gris {
  background-color: #fff;
  color: var(--verde-gris);
  border: 1px solid var(--verde-gris);
}

.eco__card-btn .vermas__btn.naranjo-rosado {
  background-color: #fff;
  color: var(--naranjo-rosado);
  border: 1px solid var(--naranjo-rosado);
}

.eco__card-btn .vermas__btn.morado:hover {
  background-color: var(--morado) !important;
  color: #fff !important;
  border : 1px solid var(--morado) !important;
}

.eco__card-btn .vermas__btn.verde:hover {
  background-color: var(--verde) !important;
  color: #fff !important;
  border : 1px solid var(--verde) !important;
}

.eco__card-btn .vermas__btn.celeste:hover {
  background-color: var(--celeste) !important;
  color: #fff !important;
  border : 1px solid var(--celeste) !important;
}

.eco__card-btn .vermas__btn.naranja2:hover {
  background-color: var(--naranja2) !important;
  color: #fff !important;
  border : 1px solid var(--naranja2) !important;
}
.eco__card-btn .vermas__btn.verde-gris:hover {
  background-color: var(--verde-gris) !important;
  color: #fff !important;
  border : 1px solid var(--verde-gris) !important;
}

.eco__card-btn .vermas__btn.naranjo-rosado:hover {
  background-color: var(--naranjo-rosado) !important;
  color: #fff !important;
  border : 1px solid var(--naranjo-rosado) !important;
}

/* ******************************** */
/* COMPLEMENTOS */

.botonllamar{
  background: none !important;
  font-weight: bold;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
 
}



/* **** SPINER CUADRADO SIN TEXTO ******** */
.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.7;
  color: #eee;
  padding: 2rem;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  font-weight: 900;
  z-index: 10;
}

.loadingGlobal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #eee;
  padding: 2rem;
  width: 100vw;
  height: 100vh;
  font-size: 2rem;
  font-weight: 900;
  z-index: 102;
}

.loadingImg {
  background-color: rgba(200, 200, 200, 0.7);
}

/* **** SPINER CUADRADO CON TEXTO ******** */
.loadingCuadrado {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
}

.loadingCuadrado:before,
.loadingCuadrado:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
}

.loadingCuadrado:before {
  animation: cuadradoBefore 2s infinite;
}

.loadingCuadrado:after {
  animation: cuadradoAfter 2s infinite;
}

.loadingTexto {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.75);
  color: #eee;
  padding: 2rem;
  width: 100vw;
  height: 100vh;
  font-size: 2rem;
  font-weight: 900;
  z-index: 10;
}

.textosDeCarga {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 30vh;
  width: 80%;
  text-align: center;
  color: #ddd;
}

.fallbackTexto {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to bottom, #c2cfdf, #f1f5f8);
  color: #123;
  padding: 2rem;
  width: 100vw;
  height: 100vh;
  font-size: 2rem;
  font-weight: 900;
  z-index: 10;
}

/* Network Status */
.web__online,
.web__offline {
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 100;
  padding: 1rem;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  background-color: #1b5e20;
  color: #eee;
  animation: internet 3s ease-in-out;
}

.web__offline {
  background-color: #b71c1c;
}

/* FALLBACKS */
.fallback__container {
  display: flex;
  justify-content: center;
}

.fallback__cards {
  width: 80%;
  height: 80vh;
  margin: 2rem auto;
}

.spinnerCircular {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-left-color: #fff;

  animation: spinCircular 1s ease infinite;
}

/* Círculos que indican el puntaje en el informe de Diagnóstico financiero */
.dot_score_bueno {
  height: 25px;
  width: 25px;
  background-color: var(--naranjo-rosado);
  border-radius: 50%;
  display: inline-block;
}

.dot_score_regular {
  height: 25px;
  width: 25px;
  background-color: #91DDF8;
  border-radius: 50%;
  display: inline-block;
}

.dot_score_deficiente {
  height: 25px;
  width: 25px;
  background-color: #FFD42F;
  border-radius: 50%;
  display: inline-block;
}

.dot_score_critico {
  height: 25px;
  width: 25px;
  background-color: #EC111A;
  border-radius: 50%;
  display: inline-block;
}

/* MODAL ENCUESTA COURSERA */
.btnCierraModal {
  border: none;
  background-color: #ffffff00;
  position: absolute;
  right: 5px;
  top: 10px;
}

.close-modal {
  padding-left: 2px;
  height: 35px !important;
  right: -60px !important;
  display: flex !important;
  position: absolute !important;
  background: #fff !important;
  border-radius: 50% !important;
  width: 35px !important;
  font-size: 27px !important;
  color: #474747 !important;
  border: transparent !important;
  margin-bottom: 20px;
  outline: none;
}

.btnTermsClosePoli {
  margin-top: 44px;
  outline: none;
  margin-right: 18px;
}

.newCloseModalButton {
  position: absolute !important;
  top: -30px !important;
  right: -1px !important;
}

@media screen and (min-width: 1400px) {
  .row-cols-xxl-3 > .agenda__detalle {
    border-right: 1px solid #AFB1B6;
  }

  .row-cols-xxl-3 > .agenda__detalle:nth-child(3n),
  .row-cols-xxl-3 > .agenda__detalle:last-child {
    border-right: none;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .row-cols-xl-2 > .agenda__detalle:nth-child(2n-1) {
    border-right: 1px solid #AFB1B6;
  }

  .row-cols-xl-2 > .agenda__detalle:last-child {
    border-right: none;
  }
}

@keyframes spinCircular {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* CIERRE DE SESION */
.cierresesion__container {
  width: 25%;
  margin: 0 auto;
  background-color: #fff;
  padding: 4rem 2rem 4rem 2rem;
  color: var(--texto);
  text-align: center;
  border-radius: 1rem;
}

.cierresesion__container img {
  height: 4rem;
}

.cierresesion__container h2 {
  font-size: 1.6rem;
  margin-top: 1rem;
}

.cierresesion__container p {
  font-size: 1.4rem;
  margin: 1rem 0 2rem 0;
  font-weight: normal;
}

.cierre__btn {
  background-color: var(--rojo);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  gap: 1rem;
  width: 20rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  padding: .8rem 1.5rem;
  cursor: pointer;
}

.cierre__btn span {
  font-size: 1.4rem;
}

.group__grafico-item label {
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
}

.swal2-actions button {
  border-radius: 8px !important;
  border: 1px solid #7066e0;
}

.breakLine-mobile {
  display: none;
}

.campos__codigo {
  display: flex;
  padding-bottom: 3rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.codigo-field {
  width: 29px !important;
  height: 42px  !important;
  margin: 0 4.5px 0 4.5px;
  border-radius: 6px;
  text-align: center;
  border: solid 1px #666666;
  background-color: #fff;
  font-size: 1.8rem;
}


/* INICIO react-verification-input */


.vi__container{
  gap: 0 !important;
  width: 229px !important;
}

.vi__character--selected {

  outline: 1px solid #000000;

}









/* FIN react-verification-input */








.reenviar__codigo {
  cursor: pointer;
  color: var(--rojo);
  text-decoration: underline;
  font-family: 'Scotia-Bold', 'Scotia', 'Open Sans', sans-serif;
}

.div__checkboxes,
.div__radiobuttons {
  padding-top: 2rem;
}

.div__checkboxes > label,
.div__radiobuttons > label {
  font-size: 1.4rem;
  padding-bottom: 0.5rem;
}

.div__checkboxes input,
.div__radiobuttons input {
  margin-right: 0.8em;
  width: 1.6rem;
  height: 1.6rem;
  accent-color: var(--rojo);
  position: absolute;
}

.label__radiobutton {
  position: relative;
}

.checkmark {
  position: absolute;
  top: -1px;
  left: -1px;
  height: 18px;
  width: 18px;
  background-color: #D9D9D9;
  border-radius: 50%;
}

.checkmark:after {
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.div__radiobuttons input ~ .checkmark:after,
.div__radiobuttons input:checked ~ .checkmark:after {
  display: block;
}

.label__radiobutton input:checked ~ .checkmark {
  background-color: #2196F3;
}
.label__radiobutton input:checked ~ .checkmark.naranjo-rosado {
  background-color: var(--naranjo-rosado);
}
.label__radiobutton input:checked ~ .checkmark.morado {
  background-color: var(--morado);
}
.label__radiobutton input:checked ~ .checkmark.verde {
  background-color: var(--verde);
}
.label__radiobutton input:checked ~ .checkmark.celeste {
  background-color: var(--celeste);
}


.texto__error {
  color: var(--rojo);
  font-size: 1.6rem;
}

.form-checkup{
  font-size: 1.2rem;
}

.button__previous__carousel,
.button__next__carousel {
  border: none;
  background: none;
  position: relative;
}

.button__previous__carousel {
  right: 20px;
}

.button__next__carousel {
  left: 20px;
}

.button__previous__carousel:disabled,
.button__next__carousel:disabled {
  opacity: 0.2;
}

.preguntas__modal__individual {
  display: flex;
  flex-direction: column;
  margin: 0px 192px;
}

.text-conoce-curp-individual {
  text-align: end !important;
  padding-right: 0px;
}

/* .asyncselect-salud-agenda > div {
  height: 43px;
  background-color: #fff !important;
} */

.asyncselect-salud-agenda > div > div > div > input,
.asyncselect-codigoPostal-buro > div > div > div > input {
  opacity: 1 !important;
}

.asyncselect-codigoPostal-buro > div > div > div > input:disabled {
  color: #8D8D8D !important;
}

.asyncselect-codigoPostal-buro > div > div > div {
  visibility: visible !important;
}



.llamar-telefono {
  font-size: 14px;
  line-height: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: bold;
}

.boton_carousel {
  cursor: pointer;
  width: 35px;
  height: 35px;
}

@media screen and (min-width: 768px) {
  .llamar-telefono,
  .llamar-telefono-mobile {
    cursor: default;
    pointer-events: none;
  }
}

@media (max-width: 1214px) {
  .cierresesion__container {
    width: 50%;
  }
}

@keyframes cuadradoBefore {
  0% {
    width: 0.5em;
    box-shadow: 1em -0.5em rgba(248, 239, 237, 0.75),
      -1em 0.5em rgba(187, 239, 237, 0.75);
  }

  35% {
    width: 2.5em;
    box-shadow: 0 -0.5em rgba(248, 239, 237, 0.75),
      0 0.5em rgba(187, 239, 237, 0.75);
  }

  70% {
    width: 0.5em;
    box-shadow: -1em -0.5em rgba(248, 239, 237, 0.75),
      1em 0.5em rgba(187, 239, 237, 0.75);
  }

  100% {
    box-shadow: 1em -0.5em rgba(248, 239, 237, 0.75),
      -1em 0.5em rgba(187, 239, 237, 0.75);
  }
}

@keyframes cuadradoAfter {
  0% {
    height: 0.5em;
    box-shadow: 0.5em 1em rgba(32, 217, 216, 0.75),
      -0.5em -1em rgba(32, 175, 216, 0.75);
  }

  35% {
    height: 2.5em;
    box-shadow: 0.5em 0 rgba(32, 217, 216, 0.75),
      -0.5em 0 rgba(32, 175, 216, 0.75);
  }

  70% {
    height: 0.5em;
    box-shadow: 0.5em -1em rgba(32, 217, 216, 0.75),
      -0.5em 1em rgba(32, 175, 216, 0.75);
  }

  100% {
    box-shadow: 0.5em 1em rgba(32, 217, 216, 0.75),
      -0.5em -1em rgba(32, 175, 216, 0.75);
  }
}

@keyframes internet {
  0% {
    height: 0;
    color: transparent;
  }

  70% {
    height: 100%;
    color: #fff;
  }

  100% {
    height: 0;
    color: transparent;
  }
}

@media screen and (max-width: 768px) {

  .activacuenta__btn,
  .unirme__btn {
    width: 100%;
    padding: 1.5rem 2rem;
    font-size: 2.4rem;
  }

  .react-calendar__tile.react-calendar__month-view__days__day:not(:disabled),
  .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from,
  .react-calendar__navigation__arrow:not(:disabled) {
    color: #000000;
  }

  .calendario-empleabilidad .react-calendar__tile--active,
  .calendario-emprendimiento .react-calendar__tile--active,
  .calendario-salud-financiera .react-calendar__tile--active,
  .calendario-cuidados .react-calendar__tile--active {
    color: white !important ;
  }

}

/* *************************************** */
/* MEDIA QUERYS */
/* *************************************** */

@media screen and (min-width: 768px) {

  .mobile {
    display: none !important;
  }

  .mobile-flex {
    display: none !important;
  }

  .desktop {
    display: inherit !important;
  }


}



@media screen and (max-width: 640px) {

  .activacuenta__btn,
  .unirme__btn {

    font-size: 1.8rem;
  }

  .iniciaProceso__btn {
    width: 100%;
    background-color: var(--rojo);
    padding: 1rem 1.4rem;
    border-radius: 8px;
    border: 1px solid var(--rojo);
    margin-top: 2rem;
    color: #fff !important;
    font-weight: 700;
    font-size: 1.7rem;
    cursor: pointer;
    text-align: center;
  }


  .subtitulo__group-ul {
    flex-direction: column;
  }

  .homeout__btn {
    /* width: 15rem;
    padding: .5rem 1rem; */
    font-size: 1.4rem;
  }

  /* CIERRE DE SESION */
  .cierresesion__container {
    width: 90%;
    padding: 3rem 1rem;
  }

  .taxi__btn {
    width: 80%;
  }

  .cuestionario__checkbox {
    vertical-align: top;
  }

  .breakLine-mobile {
    display: block;
  }

  .boton_carousel {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

}

@media screen and (min-width: 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 871px;
}
}
