footer {
  border-top: 1px solid rgba(200, 200, 200, 0.7);
  /* margin-top: 2rem; */
  padding-top: 2rem;
}
.footer__terminos-politica,
.footer__logos,
.footer__contacto,
.footer__webs,
.footer__telefono {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--plomo);
  gap: 3rem;
  /* margin-bottom: 1rem; */
}
.footer__terminos {
  cursor: pointer;
}
.footer__contacto {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.footer__logos div img {
  height: 7.3rem;
  width: 34rem;
}
.footer__logos {
  gap: 0;
  padding: 1rem;
}
.separador__derecho {
  border-right: 1px solid var(--rojo);
  padding-right: 1.5rem;
  margin: auto 0;
}
.separador__centro {
  border-right: 1px solid var(--rojo);
  border-left: 1px solid var(--rojo);
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.footer__sello {
  height: 5rem;
  margin-left: 5rem;
}
.footer__telefonos {
  margin: auto 0;
}
.footer__telefono {
  gap: 0.2rem;
  color: var(--rojo);
  font-weight: bold;
  margin: 0.5rem 0;
}
.footer__webs {
  background-color: var(--fondo-plomo);
  padding: 1.5rem;
}

/*Login*/


.textoFormularioMail > p {
  /* font-family: "Open Sans"; */
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8e8e93;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  margin-left: 10px;
}

.textoFormulariopass {
  /* font-family: "Open Sans"; */
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8e8e93;
  /* margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  max-width: 310px; */
  margin: 20px auto;
}

.cajaregistra {
  background-color: #fbd1d3;
  color: #363636 !important;
  padding-top: 15px;
  padding-bottom: 15px;
  /* font-family: "Open Sans"; */
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}


.error__volver{
  margin-top: 2rem;
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;
}
.error__informativo p{
  font-size: 1.6rem;
}
.error__informativo p span{
  color: #f5a623;
  cursor: pointer;
}
.cajaalerta {
  background-color: #fff2cd;
  color: #ffc107;
  padding-top: 15px;
  padding-bottom: 15px;
  /* font-family: "Open Sans"; */
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  border: #ffc107 1px solid;
}

.linkRegistra {
  color: #ec1c24;
  text-decoration: underline;
  font-size: 14px !important;
  text-align: center;
  margin-top: 10px;
}


.terminos {
  /* font-family: "Open Sans"; */
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8e8e93;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  max-width: 310px;
}

.colorterminos {
  color: #007bff;
  cursor: pointer;
}
/* PASSWORD */

.form-control2 {
  margin-top: 12px;
  border-radius: 3px;
  border: solid 1px #bababa;
  /* background-color: #ffffff ; */
  /* background-color: var(--input); */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #9b9b9b;
  display: block;
  width: 100%;
  height: 3.5rem;
  padding: 0.6rem 1.2rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 50px;
}
.form-control {
  outline: none;
  width: 100%;
  height: 100%;
  background-color: var(--input);
  color: #121212;
  border: 1px solid var(--borde);
  border-radius: 0.5rem;
  position: absolute;
  top: 0rem;
  left: 0;
  right: 0;
  bottom: 0rem;
  padding: 0.6rem 1.2rem;
  box-sizing: border-box;
  font-size: 1.4rem;
}


.btnMain {
  font-weight: bold;
  border: none;
  border-radius: 1rem;
  font-size: 16px;
  background-color: var(--rojo);
  color: #ffffff;
  padding: 10px 15px;
  display: block;
  width: 100%;
  margin-top: 2.5rem;
  cursor: pointer;
}
.btnMainInactivo {
  /* font-family: "Open Sans"; */
  font-weight: bold;
  border: 0px;
  border-radius: 50px;
  font-size: 16px;
  background-color: ffffff;
  color: var(--texto); /* #ec1c24; */
  padding: 10px 15px;
  display: block;
  width: 100%;
  margin-top: 2.5rem;
  /* cursor: pointer; */
}
.d-none {
  display: none;
}

.top20 {
  margin-top: 20px;
}

.tituloSuccess {
  /* font-family: "Open Sans"; */
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  color: #7dc855 !important;
  text-align: center;
}

.cajaValidaPass {
  background-color: #f6f8fa;
  padding: 15px;
  height: auto;
  width: 100%;
  margin: 15px 0;
}

.textoFormularioPassReg > li {
  /* font-family: "Open Sans"; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8e8e93;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  list-style-type: circle;
  margin-left: 15px;
}

/* ******************************************************************** */
/* MEDIA QUERY */
/* ******************************************************************** */

@media screen and (max-width: 768px) {
  footer {
    /* border-top: 1px solid rgba(200, 200, 200, 0.7); */
    /* padding-top: 2rem; */
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
  }

  .footer__terminos-politica,
  .footer__logos.media {
    flex-direction: column;
    gap: 2rem;
  }

  .footer__logos.media div img {
    height: 3.6rem;
    margin-bottom: 1rem;
  }

  .separador__derecho.media {
    border-right: inherit;
    padding-right: 1.5rem;
    margin: auto 0;
  }
  .separador__centro.media {
    border-right: inherit;
    border-left: inherit;
  }
  .separador__izquierdo.media {
    padding-left: 1.5rem;
  }
  .footer__logos div img {
    height: 100%;
    width: 17.6rem;
  }

  .footer__terminos-politica {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--plomo);
    gap: 3rem;
    width: 80%;
    margin: 0 auto;
    text-align: center
  }

  .footer__contacto {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: center;
    margin: 2rem auto;
  }

  .footer__telefono i {
    font-size: 3.4rem;
  }
  .footer__telefono p {
    font-size: 1.6rem;
  }

  .footer__webs p {
    width: 90%;
    text-align: center;
  }

  .salud_alerta-check{
    margin: 2rem auto 1rem 0 !important;
    width: 95% !important;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 640px){

  .footer__logos.media div img {
    height: 2.4rem;
  }


  .footer__terminos,
  .footer__contacto{
    font-size: 1.4rem;
  }
  .footer__telefono i{
    font-size: 1.8rem;
  }
  .footer__telefono p{
    font-size: 1.4rem;
  }
  .footer__webs.mobile{
    display: none;
  }


  .error__informativo p{
    font-size: 1.4rem;
  }
 
  .cajaValidaPass {
    padding: .5rem;
    margin: .5rem 0;
    font-size: 1.2rem;
  }
  .textoFormularioPassReg > li{
    font-size: 1rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .separador__derecho {
    border-right: none;
  }


}


input[type='radio']:checked:after{
  background-color: red;
}