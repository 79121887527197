.salarios__container {
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr)); */
  gap: 2.5rem;
  color: var(--texto);
}

.salarios_filtro {
  flex: 1.3;
  /* border: 1px solid var(--texto-claro); */
  /* background-color: #FBFBFB; */
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.077);
  border-radius: 2rem;
}

.salarios_ofertas {
  flex: 4;
}

.salarios_ofertas h1 {
  font-size: calc(1rem + 2vw) !important;
  margin-bottom: 2rem;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
  text-align: center;
}

.salarios__filtro-titulo {
  padding: 1.5rem;
  display: flex;
  font-size: 1.6rem;
  font-weight: normal;
  align-items: flex-start;
  gap: 2rem;
  border-bottom: none;
  color: black;
}

.salarios__filtro-titulo h3 {
  margin: 10px auto 0;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
}

.salarios__filtro-opciones {
  border: 1px solid var(--texto-claro);
  padding: 1.5rem;
}

.salario__group {
  margin-bottom: 2rem;
}

.salario__group>p {
  margin: 0;
  font-weight: bold;
}

.salario__group select {
  width: 100%;
  height: fit-content;
  padding: 1.4rem;
  color: black;
  font-size: 1.5rem;
  border: 1px solid var(--plomo);
  border: 1px solid #979797;
  border-radius: 0.5rem;
  font-family: 'Scotia', 'Open Sans', sans-serif;
  background-color: #F9FAFC;
}

.salario__group .salario__input-search {
  width: 100%;
  color: var(--texto);
  background-color: #F6F6F6;
  outline: none;
  padding: 1.4rem;
  border-radius: 0.8rem;
  border:solid 2px #CCCCCC;
  font-weight: bold;
  font-size: 1.4rem;
  max-width: 550px;
}

.salario__group-rangos {
  overflow-y: scroll;
  height: 30rem;
}

/* custom scrollbars */
.salario__group-rangos::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.salario__group-rangos::-webkit-scrollbar-track {
  background-color: #ECEEF0;
  border-radius: 10px;
}

/* Handle */
.salario__group-rangos::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 10px;
}

/* Handle on hover */
.salario__group-rangos::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.salario-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  /* cursor: pointer; */
}

.salario-item label {
  padding: 0.5rem;
  cursor: pointer;
  flex: 1;
  font-size: 14px;
  font-family: 'Scotia', 'Open Sans', sans-serif;
}

.salario-item label:hover {
  background-color: var(--texto-claro);
}

.salario-item input {
  cursor: pointer;
  width: 1.5rem;
  /* height: 1.5rem; */
  /* padding: .8rem; */
}

.salario-item input:hover {
  background-color: var(--texto-claro);
}

/*    RADIO as CHECKBOX*/

/* div.radio-as-checkbox > label > input {
	visibility: hidden;
} */

div.radio-as-checkbox > label > input + span {
	/* display: block; */
	/* margin: 0 0 0 -10px; */
	padding: 0 0 10px 10px;  
	/* height: 20px; */
	/* width: 150px; */
}

div.radio-as-checkbox input::after {
  content: ''; 
  display: block;
	padding: 0px;
	height:16px;
	width:16px;
  border-radius: 2px;
  border: 1px solid #979797;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  /* content:url(mycheckbox-checked.png) */
}

div.radio-as-checkbox input:checked::after{
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgOSA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNOC44MTE2OCAwLjE3NTc1NEM5LjA2Mjc3IDAuNDEwMDkyIDkuMDYyNzcgMC43OTA2NTggOC44MTE2OCAxLjAyNUwzLjY2OTQgNS44MjQyNUMzLjQxODMxIDYuMDU4NTggMy4wMTA1NSA2LjA1ODU4IDIuNzU5NDYgNS44MjQyNUwwLjE4ODMxNiAzLjQyNDYyQy0wLjA2Mjc3MiAzLjE5MDI4IC0wLjA2Mjc3MiAyLjgwOTcyIDAuMTg4MzE2IDIuNTc1MzhDMC40Mzk0MDQgMi4zNDEwNCAwLjg0NzE3MSAyLjM0MTA0IDEuMDk4MjYgMi41NzUzOEwzLjIxNTQzIDQuNTQ5NDVMNy45MDM3NSAwLjE3NTc1NEM4LjE1NDg0IC0wLjA1ODU4NDYgOC41NjI2IC0wLjA1ODU4NDYgOC44MTM2OSAwLjE3NTc1NEg4LjgxMTY4WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==") !important;
}

/* ******************************** */
.salario__group-ofertas {
  /* border: 1px solid var(--texto-claro); */
  min-height: 57rem;
}

.salario__group p {
  margin-bottom: 2rem;
}

.salario__oferta {
  border: solid 2px #CCCCCC;
  border-radius: 1rem;
  margin-top: 3rem !important;
}

.salario__oferta-resumen {
  /*  background-color: #f7f7f7; */
  display: flex;
  justify-content: space-between;
  padding: 3rem;
}

.salario__oferta-resumen p:nth-child(1) {
  font-size: 1.6rem;
  font-weight: bold;
}

.salario__oferta-detalle {
  padding: 0rem 3rem 2rem 3rem;
  font-weight: normal;
  color: var(-texto);
}

.oferta__btn {
  display: flex;
  justify-content: flex-end;
}

.oferta__btn button {
  color: var(--morado);
  border: 1px solid var(--morado);
  padding: 1.5rem 3rem;
  border-radius: 1rem;
  background-color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin-right: auto;
}

.oferta__btn button:disabled {
  color: var(--gris);
  border-color: var(--gris);
  cursor: no-drop;
}

/* ******************** */

.comparasalario__container {
  /* margin-bottom: 1rem; */
  padding: 3rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.16);
  border-radius: 1rem;
  width: 80%;
  margin: auto;
}

.comparasalario__user {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  border-bottom: solid 1px var(--borde);
  padding-bottom: 3rem;
}

.comparasalario__user h3 {
  margin-left: 1rem;
  font-size: 2rem !important;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
  font-weight: 700;
}

.comparasalario__filtros {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr)); */
  grid-template-columns: repeat(2, 1fr);
  gap: 2.3rem;
  color: var(--texto);
  margin: auto;
}

.comparasalario__group>p {
  margin: 0;
  font-weight: bold;
}

.comparasalario__filtros select,
.comparasalario__filtros input {
  width: 100%;
  color: var(--plomo);
  border: 1px solid var(--borde);
  border-radius: 0.5rem;
  outline: none;
  padding: 16px !important;
  background-color: rgb(252, 252, 252);
}

.salario__group select,.comparasalario__filtros select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 16px center ;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMkwxMCAxMEwxOCAyIiBzdHJva2U9IiM3ODQ5QjgiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=");
}
.salario__group select{
  background-size: 14px;
}

.comparasalario__filtros input {
  /* width: 70%; */
  color: var(--texto);
}

/* ******************** */
.group__graficos-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.group__graficos {
  padding: 3rem;
  width: 48%;
  margin: 0 auto;
  border: 1px solid #A7A7A7;
  border-radius: 5px;
  background-color: #F6F6F6;
  /* display: flex;
  align-items: center; */
}

.group__grafico-item-text h3 {
  margin-right: auto;
  font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
}

.group__graficos.last {
  width: 100%;
}

.group__graficos h3 {
  margin-bottom: 2rem;
}

.group__grafico-item,
.group__grafico-item-text {
  flex: 2;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.grafico canvas{
font-family: 'Scotia', 'Open Sans', sans-serif !important;
}

.group__grafico-item-text {
  flex: 1;
}

.group__grafico-item-text i,
.group__grafico-item-text h3 {
  font-size: 1.6rem;
  /* max-width: 10rem; */
}

.grafico {
  height: 40rem;
  /* min-width: 60rem */
  width: 100%;
  /* height: 100% */
  padding-bottom: 3rem;
}

.marginTop0 {
  margin-top: 0;
}

.fondoProgressbar {
  height: 15px;
  background-color: #E1E8ED;
  width: 100%;
  border-radius: 41px;
  margin: 15px auto;
}

.fondoProgressbarCarga {
  height: 15px;
  background-color: var(--morado);
  width: 0%;
  border-radius: 41px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 0 0;
}

.graficogrid {
  display: grid;
  grid-template-columns: repeat(1, minmax(18rem, 1fr));
  gap: 1rem;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
}

.graficogrid > div:last-child{
  display: none;
}

.importantdata {
  color: #1E1E1E;
  padding: 20px 0;
}

@media screen and (max-width: 640px) {
  .oferta__btn button {
    margin: 0;
  }

  .oferta__btn{
    justify-content: center;
  }

  .comparasalario__filtros {
    grid-template-columns: repeat(1, 1fr);
  }


  .group__graficos {
    display: block;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
    width: 100%;
    padding: 0;
  }

  .group__graficos.salario_promover,
  .group__graficos.last {
    padding: 0rem 1rem;
}

  .grafico {
    width: 100%;
  }

  .graficogrid {
    width: 100%;
    margin: 0;
  }

  .group__grafico-item {
    display: block;
  }

  .textLeft {
    text-align: left !important;
  }

  .comparasalario__container {
    width: 100%;
  }

  .anchototal {
    width: 100% !important;
  }

  .group__grafico-item-text h3 {
    text-align: center !important;
    margin: 4rem auto;
  }
}