.login__container {
  display: flex;
  /*height: calc(100vh - 8rem);*/
  margin-bottom: 1.5rem;
}

.login__container-imagenes {
  flex: 5;
  position: relative;
  margin-right: 6rem;
}

.login__container-datos {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3rem;
  flex: 6;
}

.login__container-imagenes > img {
  height: 666px;
  width: 100%;
  object-fit: cover;
  object-position: center top;
}

.login__imagenes-textos {
  position: absolute;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  bottom: 0%;
  left: 0;
  padding: 4rem 8rem;
}

.login__imagenes-card {
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(0.5rem);
  border-radius: 1rem;
  padding: 2rem 5rem;
  line-height: 3rem;
  font-size: 2.1rem;
  font-family: 'Scotia-Bold';
}

.login__imagenes-card-footer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  font-size: 2.1rem;
}

.login__imagenes-flechas {
  display: flex;
  justify-content: center;
  gap: 3rem;
  height: 5rem;
}

.login__imagenes-flechas > img {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 0.5rem;
}

/* *************************************************** */
/* ZONA DEL FORMULARIO */
/* *************************************************** */

.auth__login {
  min-width: 30rem;
  width: 419px;
  max-width: 419px;
  margin-top: 3.5rem;
}

.auth__title {
  color: var(--texto-dark);
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
  font-family: "Scotia-Headline", "Scotia", "Open Sans", sans-serif;
}

.auth__subtitle {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--texto);
  text-align: center;
  margin-bottom: 1rem;
}

.auth__rutas {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  font-size: 1.4rem;
}

.auth__logueo-text {
  font-weight: 400;
}

.auth__logueo-btn {
  font-weight: 400;
  color: var(--rojo);
  cursor: pointer;
  margin-right: 9rem;
}

.auth__login-label {
  margin-top: 2rem;
  font-size: 1.6rem;
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
}

.auth__login-group {
  display: flex;
  padding-bottom: 1rem;
}

.auth__login-group select {
  width: 8rem;
  padding: 1.5rem 1.2rem;
  border-radius: 1rem 0 0 1rem;
  border: 1px solid var(--borde);
  outline: none;
}

.auth__login-group #Cobertura {
  border-right: none;
}

.auth__login-docompleto,
.auth__login-doc {
  flex: 1;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid var(--borde);
  outline: none;
}

.auth__login-docompleto {
  border-radius: 0.5rem;
}

.auth__login-pass {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.auth__pass {
  outline: none;
  width: 100%;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid var(--borde);
}

.auth__pass::-ms-reveal,
.auth__pass::-ms-clear {
  display: none;
}

.pass__icon {
  position: absolute;
  height: 100%;
  top: 50%;
  right: 0rem;
  transform: translateY(-50%);
  padding: 1.5rem;
  cursor: pointer;
  font-size: 1.6rem;
}

.btn__login {
  font-family: "Scotia-Bold", "Scotia", "Open Sans", sans-serif;
  border: none;
  border-radius: 0.5rem;
  font-size: 18px;
  background-color: var(--rojo);
  color: #ffffff;
  padding: 1rem 1.5rem;
  display: block;
  width: 262px;
  margin: 2.5rem auto;
  cursor: pointer;
}

/*.btn__login.completo {
  width: 75%;
}*/

.btn__login:disabled {
  cursor: no-drop;
  background-color: #a7a7a7;
  border-color: 1px solid #a7a7a7;
}

/* ERRORES */
.cajaregistra__error {
  border: 1px solid #f5a623;
  padding: 1rem 1rem;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.cajaregistra__error i {
  color: #ffc107;
  font-size: 2.5rem;
}

.error__title,
.error__volver {
  max-width: 35rem;
  color: var(--rojo);
  text-align: center;
  font-size: 1.6rem;
}

/* Mensajes de preguntas sugeridas */
.preguntas__listado {
  flex: 1;
}

.preguntas__group {
  margin-top: 2rem;
  border-bottom: 1px solid var(--texto-claro);
  padding: 1rem 0 3rem 0;
}

.pregunta__titulo {
  font-size: 1.8rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: var(--texto);
}

.pregunta__titulo h4 {
  font-size: 1.4rem;
  font-size: 16px;
  color: var(--texto-dark);
  font-weight: 700;
}

.pregunta__detalle {
  padding: 1rem 0 0 0;
}

.pregunta__detalle p {
  font-family: Scotia, sans-serif;
  font-size: 2rem;
  color: var(--texto);
}

.label__checkbox p,
.label__radiobutton p {
  padding-left: 2.5rem;
}

@media screen and (max-width: 640px) {
  .login__container {
    /*height: calc(80vh);*/
    margin-bottom: 0rem !important;
  }

  .auth__login {
    /* min-width: 30rem; */
    max-width: 30rem;
  }

  .auth__subtitle {
    margin-top: 1rem;
    text-align: start;
  }

  .auth__logueo-btn{
    text-decoration: underline;
    margin: 0;
  }

  .auth__login-label {
    font-size: 1.4rem;
  }

  .cajaregistra__error {
    max-width: 30rem;
    padding: 0.5rem;
    margin: 0;
  }

  .cajaregistra__error i {
    font-size: 1.8rem;
  }

  .btn__login {
    width: 100%;
  }

  .btn__login.completo {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .auth__login {
    padding: 0 30px;
  }
}
