.container-cookie {
    position: relative;
}

.bannerInicial {
    max-width: 1640px !important;
    width: 100%;
    margin: auto;
}

.row.bannerI {
    width: 100%;
    margin: auto;
}

.banner__logo{
    width: 250px;
    padding-left: 3rem;
    padding-top: 1.5rem;
    margin-bottom: 1rem;
    position: absolute;
}

.home__text-container {
    margin-left: 1rem;
    margin-right: 1rem;
}

.success__title h2 {
    color: var(--rojo);
    font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
    font-weight: bold;
    margin-top: 2rem;
    font-size: 4.5rem;
}

.home__text p {
    font-family: var(--font-regular);
    font-weight: normal;
    font-size: 1.8rem;
}

.btn__beneficios {
    background-color: var(--rojo);
    padding: 1.2rem 2rem;
    border-radius: 8px; 
    border: 1px solid var(--rojo);
    margin-top: 2rem;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

.success__image-container {
    height: 100%;
    background-color: var(--celeste-success);
}

.home__image_desks {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.subtitulo__404 {
    font-size: 2.2rem;
    font-family: 'Scotia-Headline', 'Scotia', 'Open Sans', sans-serif;
    padding-bottom: 1rem;
}

@media screen and (min-width: 1200px) {
    .home__text-container {
        display: flex;
        padding-left: 2rem;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .success__title h2 {
        width: 90%;
    }
}

@media screen and (max-width: 640px) {
    .btn__inicio {
        width: 100% !important;
        margin-bottom: 5rem;
    }

    .banner__logo {
        position: inherit;
        padding-right: 1rem;
        padding-left: 1rem;
        width: 17rem;
    }

    .home__text-container {
        text-align: center;
    }
}
