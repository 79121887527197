.gridCards{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 12fr));
    width: 100%;
}

.row2Q{
    width: 90%;
}


.btnFin{
    background-color: var(--morado);
    border: 1px solid var(--morado);
    color: rgb(255, 255, 255);
    margin: 2rem;
    padding: 1rem 2rem;
    text-align: center;
    font-size: 1.8rem;
    border-radius: 4rem;
}

.btnFinalizar{
    /* font-family: 'Open Sans'; */
    font-weight: 700;
    border-color: #7849b8 2px solid;
    border: 0;
    border-radius: 50px;
    font-size: 18px;
    background-color: #7849b8;
    color: #fff;
    padding: 10px 15px;
    width: 315px;
    height: 58px;
    margin-top: 30px;
    cursor: pointer;
}

.btnPerfil{
    /* font-family: GoogleSans; */
    font-weight: bold;
    border-color: #ec1c24 2px solid;
    border: 0px;
    border-radius: 50px;
    font-size: 16px;
    background-color: #ec1c24;
    color: #ffffff;
    padding: 10px 15px;
    width: 256px;
    margin-top: 1.5rem!important;
    text-align: center;

}

.btnañadir{
    /* font-family: Helvetica; */
    font-size: 12px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: -.03px;
    text-align: center;
    color: #fff;
    width: 103px;
    height: 28px;
    border-radius: 14px;
    border: 0;
    background-color: #ec1c24;
}
.gridPane{
    display: flex;
    
    padding-top: 20px;
    padding-bottom: 20px;
}


.tabpanel{
    padding: 10px 30px;
    color: #828282;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 2px solid #828282;
}

.tabpanel.select{
    color: var(--rojo);
    border-bottom: 2px solid var(--rojo);
}

.tabpanel_Perfil{
    padding: 10px 30px;
    color: #828282;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 2px solid #828282;
}

.tabpanel_Perfil.select{
    color: red;
    border-bottom: 2px solid red;
}

.titulo-proteccionRes {
    text-align: initial;
    font-size: 25px;
    color: #4F4F4F;
    padding-top: 30px;
    padding-left: 20px;
    width: bold;
}

.borderNum{
    border: 1px solid #657786;
    border-radius: 8px;
    padding: 5px 5px 3px;
    margin: 0 3px;
    width: 40px;

}



.titulo-proteccionResultado {
    text-align: initial;
    font-size: 20px;
    /* font-family: 'Open Sans'; */
    font-weight: bold;
    color: #4F4F4F;
    padding-top: 10px;
    padding-left: 20px;
}

.fondoRes{
    background-color: #F1F3F9;
    width: 100%;
    height: auto;
    text-align: center;
}

.fondoText{
    background-color: #F1F3F9;
    width: 100%;
    height: auto;
    margin: 10px 0;
    padding: 10px;
    font-size: 14px;
    /* font-family: 'Open Sans'; */
    color: #4F4F4F;
    
}
.bordeDer{
    border-right: 1px solid #4F4F4F ;
}
.mid50{
    width: 50%;
}

.boxRes{
    margin: 10px auto;
}

.click{
    cursor: pointer;
}

.imgPsico{
    width: 40px;
    height: auto;
}

@media screen and (max-width: 640px) {
  
    .gridPanepadd{
      padding: 2rem 3rem;
    }
  }