.portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--fondo-transparente-opaco);
  z-index: 101;
  color: fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.portal.encuesta {
  flex-direction: column;
}
.portal__cierre {
  width: 35vw;
  display: flex;
  justify-content: flex-end;
}
.portal__cierre > div {
  background-color: #fff;
  padding: 0.5rem 0.9rem 0.2rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  cursor: pointer;
  font-weight: bold;
}
.portal__edicion {
  width: 80vw;
  background-color: #fff;
  border-radius: 2rem;
  padding: 2rem 2rem 0.5rem;
}
.portal__edicion.encuesta {
  width: 35vw;
  padding: 2rem 4rem 0.5rem;
  border-radius: 0.5rem;
}
.section__encuesta h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}
.section__encuesta p {
  color: var(--texto-encuesta);
  margin-bottom: 2rem;
  font-size: 1.4rem;
}

.form__encuesta-group {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.form__encuesta-group p span {
  color: var(--texto-encuesta);
}
.encuesta__input-normal {
  width: 100%;
  padding: 0.8rem 1.2rem;
  border: 1px solid var(--texto-claro);
  margin-top: 0.5rem;
}
.encuesta__input-normal.error {
  border: 1px solid red;
}
.form_encuesta-checks {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 1rem;
  align-items: center;
}
.form_encuesta-checks label input {
  margin-right: 0.5rem;
}
.error__encuesta {
  color: red;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.form_encuesta-checks label {
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  font-size: 1.4rem;
  user-select: none;
}
.encuesta__check {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.encuesta__checkspan {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #eee;
  border: 1px solid #ccc;
}
.encuesta__check:checked ~ .encuesta__checkspan {
  background-color: #70c04b;
  border: none;
}
.encuesta__checkspan:after {
  content: "";
  position: absolute;
  display: none;
}
.encuesta__check:checked ~ .encuesta__checkspan:after {
  display: block;
}
.encuesta__checkspan:after {
  left: 0.5rem;
  top: 0.2rem;
  width: 0.5rem;
  height: 1rem;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Modal Zona Cabecera */
.section__cabecera {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--color-morado-light);
}
.section__cabecera p {
  font-size: 2rem;
  font-weight: bold;
  margin-left: 2rem;
}
/* Modal Zona Formulario */
.section__cuerpo {
  background-color: var(--grey);
  margin: 1rem;
  border-radius: 2rem;
  padding: 1rem;
  height: 50vh;
  overflow-y: scroll;
}
.section__cuerpo.encuesta {
  overflow-y: auto;
}
.section__cuerpo p {
  margin: 2rem 0;
}

/* Pantalla terminos de Encuesta */
.encuesta__termino-title {
  width: 3rem;
  height: 3rem;
  text-align: center;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid #7dc855;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #7dc855;
}
.encuesta__termino-texto {
  text-align: center;
}
.encuesta__termino-texto h3 {
  margin: 2rem auto;
  font-size: 1.6rem;
}
.encuesta__termino-texto p {
  font-size: 1.4rem;
}

/* Seccion de botones */
.section__btn {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.section__btnItem {
  text-align: center;
  width: 15%;
  background-color: var(--rojo);
  color: #eee;
  padding: 1rem 1rem;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}
.section__btn-encuesta {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  gap: 2rem;
}
.section__btnItem-encuesta {
  text-align: center;
  width: 30%;
  color: var(--verde);
  border: 1px solid var(--verde);
  padding: 1rem 1.5rem;
  border-radius: 4rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2rem;
}
.section__btnItem-encuesta.enviar {
  background-color: #efefef;
  color: var(--texto);
  border: none;
  cursor: inherit;
}
.section__btnItem-encuesta.enviar.apto {
  background-color: var(--verde);
  color: #fff;
  border: none;
  cursor: pointer;
}

@media screen and (max-width:640px){
  .portal__cierre {
    width: 90vw;
  }
  .portal__edicion.encuesta {
    width: 90vw;
    padding: 1rem 2rem 0.5rem;
  }
  .section__encuesta h2 {
    /* text-align: center; */
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
  .section__encuesta p {
    color: var(--texto-encuesta);
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  
  .form__encuesta-group {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .form_encuesta-checks {
    margin-top: .5rem;
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr)); */
    gap: .8rem;
    /* align-items: center; */
  }

  .section__btnItem {
    width: 40%;
  }

}